import React, { Component } from "react";

import NavigationPanel from "../components/common/navigationPanel";
import ProductPage from "../components/productPage";

class SubProductPage extends Component {
  state = {
    flgGrouping: false,
    breadcrumbItems: [
      { link: "/", text: "Home" },
      { link: "/products", text: "Products" }
    ]
  };

  render() {
    const { flgGrouping, breadcrumbItems } = this.state;
    const {
      inCartProducts,
      onSelectClickHandler,
      licensedProductHandler,
      isEntitled
    } = this.props;
    const {
      foundryId,
      processId,
      familyId,
      groupId,
      totalMembers
    } = this.props.match.params;

    const selectedFoundries = [Number.parseInt(foundryId)];
    const selectedProcesses = [Number.parseInt(processId)];
    const selectedFamilies = [Number.parseInt(familyId)];
    const selectedGroups = [Number.parseInt(groupId)];
    const groupProduct = {
      is_group: true,
      foundry_id: Number.parseInt(foundryId),
      family_id: Number.parseInt(familyId),
      process_id: Number.parseInt(processId),
      product_group_id: Number.parseInt(groupId),
      total_members: Number.parseInt(totalMembers)
    };
    return (
      <React.Fragment>
        <NavigationPanel title="Physical IP" items={breadcrumbItems} />
        <ProductPage
          isSubpage={true}
          flgGrouping={flgGrouping}
          inCartProducts={inCartProducts}
          selectedFoundries={selectedFoundries}
          selectedProcesses={selectedProcesses}
          selectedFamilies={selectedFamilies}
          selectedGroups={selectedGroups}
          onSelectClickHandler={onSelectClickHandler}
          licensedProductHandler={licensedProductHandler}
          isEntitled={isEntitled}
          productGroup={groupProduct}
        />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const {
      foundryId,
      foundryName,
      processId,
      processName,
      familyId
    } = this.props.match.params;
    let {
      selectedFoundries,
      selectedProcesses,
      selectedFamilies,
      selectedGroups,
      breadcrumbItems
    } = this.state;

    selectedFoundries = [foundryId];
    selectedProcesses = [processId];
    selectedFamilies = [familyId];
    breadcrumbItems = [
      ...breadcrumbItems,
      { link: "#", text: `${foundryName} ${processName}` }
    ];

    this.setState({
      selectedFoundries,
      selectedProcesses,
      selectedFamilies,
      selectedGroups,
      breadcrumbItems
    });
  }
}

export default SubProductPage;
