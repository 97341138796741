import React, { Component } from "react";
import InCartTable from "../components/incartTable";
import NavigationPanel from "../components/common/navigationPanel";
import ConfirmDialog from "../components/common/confirmDialog";
import IncartBox from "../components/common/incartBox";
import CustomSnackbar from "../components/common/CustomSnackbar";

class CartPage extends Component {
  state = {
    openConfirmDialog: false,
    enableDisplay: true,
    breadcrumbItems: [
      { link: "/", text: "Home" },
      { link: "/carts", text: "In Cart" },
    ],
  };

  render() {
    const {
      inCartProducts,
      removeFromCartHandler,
      downloadAllHandler,
      pluginDownloadHandler,
      saveCartHandler,
      handleCloseSnackbar,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
    } = this.props;
    const { openConfirmDialog, enableDisplay, breadcrumbItems } = this.state;
    return (
      <React.Fragment>
        <div>
          <NavigationPanel title="My Cart" items={breadcrumbItems} />
          <div className="row" style={{ maxWidth: "110rem" }}>
            <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <IncartBox
                totalProduct={inCartProducts.length}
                totalSize={this.totalSize()}
                saveCartHandler={saveCartHandler}
                downloadAllHandler={downloadAllHandler}
                pluginDownloadHandler={pluginDownloadHandler}
              />
            </div>
            <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9">
              <InCartTable
                title="My cart"
                enableDisplay={enableDisplay}
                products={inCartProducts}
                total={inCartProducts.length}
                removeItemHandler={removeFromCartHandler}
                removeAllHandler={this.showRemoveAllDialog}
              />
            </div>
          </div>
        </div>
        <ConfirmDialog
          open={openConfirmDialog}
          title="Delete All"
          message="Are you sure you want to delete all products in cart?"
          okHandler={this.okHandler}
          cancelHandler={this.cancelHandler}
        />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={handleCloseSnackbar}
          variant={snackbarVariant}
        />
      </React.Fragment>
    );
  }

  cancelHandler = () => {
    this.disableRemoveAllDialog();
  };
  okHandler = () => {
    this.props.removeAllFromCartHandler();
    this.disableRemoveAllDialog();
  };

  disableRemoveAllDialog = () => {
    this.setState({
      openConfirmDialog: false,
    });
  };

  showRemoveAllDialog = () => {
    this.setState({
      openConfirmDialog: true,
    });
  };

  totalSize = () => {
    const { inCartProducts } = this.props;
    const sizes = inCartProducts.map((product) => product.size);
    return sizes.reduce((size1, size2, index) => size1 + size2, 0);
  };
}

export default CartPage;
