import React, { Component } from "react";

import { ExportToCsv, CsvConfigConsts } from "export-to-csv";

import HistoryFilter from "../components/HistoryFilter";
import { getDownloadHistoryReport } from "../services/HistoryServices";
import DownloadHistoryTable from "../components/DownloadHistoryTable";
import CustomSnackbar from "../components/common/CustomSnackbar";
import {
  SNACKBAR_VARIANT,
  GATEWAY_TIMEOUT_MSG,
  PART_LEVEL,
} from "../components/common/Constants";
import { NodeInfo } from "detect-browser";

const CSV_OPTIONS = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  filename: "download history report",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const ROWS_PER_PAGE = 50;

export default class HistoryPage extends Component {
  state = {
    loading: false,
    downloadHistories: [],
    rowsPerPage: ROWS_PER_PAGE,
    page: 0,
    totalRecords: 0,
    selectedSapCustmer: "",
    selectedUserId: "",
    selectedFoundry: "",
    selectedTechnology: "",
    selectedProcess: "",
    selectedGroup: "",
    snackbarOpen: false,
    snackbarMsg: "",
    snackbarVariant: SNACKBAR_VARIANT.ERROR,
    historyLevel: "",
    packageType: null,
    productcode: null,
  };

  render() {
    const {
      downloadHistories,
      page,
      rowsPerPage,
      totalRecords,
      loading,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
    } = this.state;
    return (
      <div className="row">
        <div className="col-md-2" style={{ paddingBottom: "20px" }}>
          <HistoryFilter
            generateReportHandler={this.generateReportHandler}
            exportHandler={this.exportHandler}
            loading={loading}
          />
        </div>
        <div className="col-md-10" style={{ paddingTop: "20px" }}>
          <DownloadHistoryTable
            histories={downloadHistories}
            page={page}
            rowsPerPage={rowsPerPage}
            loading={loading}
            onChangePageHandler={this.onChangePageHandler}
            totalRecords={totalRecords}
          />
        </div>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={this.handleCloseSnackbar}
          variant={snackbarVariant}
        />
      </div>
    );
  }

  generateReportHandler = async (params) => {
    this.setState({ downloadHistories: [], loading: true });
    const {
      selectedSapCustmer,
      selectedUserId,
      selectedFoundry,
      selectedTechnology,
      selectedProcess,
      selectedGroup,
      startedDate,
      endedDate,
      historyLevel,
      packageType,
      productcode,
    } = params;
    this.state.selectedSapCustmer = selectedSapCustmer;
    this.state.selectedUserId = selectedUserId;
    this.state.selectedFoundry = selectedFoundry;
    this.state.selectedTechnology = selectedTechnology;
    this.state.selectedProcess = selectedProcess;
    this.state.selectedGroup = selectedGroup;
    this.state.startedDate = startedDate;
    this.state.endedDate = endedDate;
    this.state.page = 0;
    this.state.rowsPerPage = ROWS_PER_PAGE;
    this.state.historyLevel = historyLevel;
    this.state.packageType = packageType;
    this.state.productcode = productcode;

    const downloadHistories = await this.getDownloadHistoryReportFromBE();
    this.setState({ downloadHistories, loading: false });
  };

  exportHandler = async (params) => {
    this.setState({
      downloadHistories: [],
      loading: true,
    });
    const {
      sapCustomerList,
      selectedSapCustmer,
      selectedUserId,
      selectedFoundry,
      selectedTechnology,
      selectedProcess,
      selectedGroup,
      startedDate,
      endedDate,
      historyLevel,
      packageType,
      productcode,
    } = params;

    this.state.selectedSapCustmer = selectedSapCustmer;
    this.state.selectedUserId = selectedUserId;
    this.state.selectedFoundry = selectedFoundry;
    this.state.selectedTechnology = selectedTechnology;
    this.state.selectedProcess = selectedProcess;
    this.state.selectedGroup = selectedGroup;
    this.state.startedDate = startedDate;
    this.state.endedDate = endedDate;
    this.state.page = 0;
    this.state.rowsPerPage = "";
    this.state.historyLevel = historyLevel;
    this.state.packageType = packageType;
    this.state.productcode = productcode;

    const downloadHistories = await this.getDownloadHistoryReportFromBE();

    try {
      const csvExporter = new ExportToCsv(CSV_OPTIONS);
      csvExporter.generateCsv(downloadHistories);
    } catch (error) {
      console.log("TCL: HistoryPage -> error", error);
    }

    this.setState({
      downloadHistories,
      loading: false,
      rowsPerPage: ROWS_PER_PAGE,
    });
  };

  onChangePageHandler = async (event, page) => {
    this.state.page = page;
    this.setState({ page, loading: true });
    const downloadHistories = await this.getDownloadHistoryReportFromBE();

    this.setState({ downloadHistories, loading: false });
  };

  getDownloadHistoryReportFromBE = async () => {
    const {
      selectedSapCustmer,
      selectedUserId,
      selectedFoundry,
      selectedTechnology,
      selectedProcess,
      selectedGroup,
      rowsPerPage,
      page,
      startedDate,
      endedDate,
      historyLevel,
      packageType,
      productcode,
    } = this.state;

    try {
      const downloadHistories = await getDownloadHistoryReport(
        selectedSapCustmer,
        selectedUserId,
        selectedFoundry,
        selectedTechnology,
        selectedProcess,
        selectedGroup,
        rowsPerPage,
        page,
        startedDate,
        endedDate,
        historyLevel,
        packageType,
        productcode
      );
      this.state.totalRecords =
        downloadHistories.length != this.state.rowsPerPage
          ? downloadHistories.length
          : downloadHistories.length * 2;
      this.state.totalRecords += this.state.page * this.state.rowsPerPage;
      return downloadHistories;
    } catch (error) {
      this.setState({
        totalRecords: 0,
        snackbarOpen: true,
        snackbarMsg: GATEWAY_TIMEOUT_MSG,
      });
      return [];
    }
  };

  handleCloseSnackbar = () => {
    const snackbarOpen = false;
    this.setState({ snackbarOpen });
  };
}
