import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function sort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const FilterItem = (
  item,
  itemKey,
  itemText,
  isSelected,
  onItemClickHandler
) => {
  const display = item[itemText] + " (" + item["count"] + ")";
  return (
    <li key={item[itemKey]}>
      <FormControlLabel
        style={{ marginLeft: "0" }}
        key={item[itemKey]}
        control={
          <Checkbox
            style={{
              display: "block",
              overflow: "hidden",
              padding: 1,
              margin: 1
            }}
            value={String(item[itemKey]) || ""}
            color="primary"
            checked={isSelected}
            onClick={() => onItemClickHandler(item)}
          />
        }
        label={display}
      />
    </li>
  );
};

class CommonFilter extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      title,
      data,
      itemKey = "id",
      itemText = "name",
      selectedItems,
      onItemClickHandler,
      onChangeHandler,
      loading
    } = this.props;
    return (
      <Card
        style={{
          marginBottom: "20px",
          width: "100%",
          overFlowX: "auto",
          minWidth: 300
        }}
      >
        <CardHeader
          title={
            loading ? (
              <div>
                {title} <CircularProgress size={25} />
              </div>
            ) : (
              `${title} (${data.length})`
            )
          }
        >
          {loading ? <CircularProgress size={25} /> : ""}
        </CardHeader>
        <CardContent style={{ maxHeight: "25vh", overflow: "auto" }}>
          <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
            {sort(data, getSorting("desc", "count"))
              .slice(0, data.length)
              .map(i => {
                const isSelected =
                  selectedItems.indexOf(i[itemKey]) + 1 === 0 ? false : true;
                return FilterItem(
                  i,
                  itemKey,
                  itemText,
                  isSelected,
                  onItemClickHandler,
                  onChangeHandler
                );
              })}
          </ul>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CommonFilter);
