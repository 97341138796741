import React, { Component } from "react";
import { any } from "prop-types";

import DownloadProductPanel from "../components/downloadProductPanel";
import NavigationPanel from "../components/common/navigationPanel";
import DownloadReportByProduct from "../components/common/DownloadReportByProduct";
import SuccessSnackbar from "../components/common/CustomSnackbar";
import {
  filterSuccessParts,
  filterFailureParts,
  filterCancelledParts
} from "../components/util/ProductUtil";
import { DOWNLOAD_STATUS } from "../components/common/Constants";

class DownloadPage extends Component {
  state = {
    product: any,
    openProductReport: false,
    successDownloadingParts: [],
    errorDownloadingParts: [],
    canceledDownloadingParts: [],
    breadcrumbItems: [
      { link: "/", text: "Home" },
      { link: "/download", text: "Download" }
    ]
  };
  render() {
    const {
      breadcrumbItems,
      openProductReport,
      product,
      successDownloadingParts,
      errorDownloadingParts,
      canceledDownloadingParts
    } = this.state;

    const {
      retryDownloadErrorProductHandler,
      cancelDownloadHandler,
      products = [],
      downloadingPartIndex,
      handleCloseSnackbar,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant
    } = this.props;

    return (
      <React.Fragment>
        <div>
          <div style={{ paddinBottom: "30px" }}>
            <NavigationPanel title="Download" items={breadcrumbItems} />
          </div>
          <div
            className="row"
            style={{
              maxWidth: "110rem",
              minHeight: "500px"
            }}
          >
            <div className="col-md-12">
              {products.map(product => (
                <DownloadProductPanel
                  key={product.product_code}
                  product={product}
                  downloadingPartIndex={downloadingPartIndex}
                  openProductReport={this.openProductReport}
                  showRedownload={product.status == DOWNLOAD_STATUS.ERROR}
                  showCancelButton={product.status == DOWNLOAD_STATUS.DOWNLOADING}
                  retryDownloadErrorProductHandler={
                    retryDownloadErrorProductHandler
                  }
                  cancelDownloadHandler={
                    cancelDownloadHandler
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <DownloadReportByProduct
          open={openProductReport}
          product={product}
          successDownloadingParts={successDownloadingParts}
          errorDownloadingParts={errorDownloadingParts}
          canceledDownloadingParts={canceledDownloadingParts}
          handleClose={this.closeProductReport}
        />
        <SuccessSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={handleCloseSnackbar}
          snackbarVariant={snackbarVariant}
        />
      </React.Fragment>
    );
  }

  closeProductReport = () => {
    this.setState({ openProductReport: false });
  };

  openProductReport = product => {
    const successDownloadingParts = filterSuccessParts(product);
    const errorDownloadingParts = filterFailureParts(product);
    const canceledDownloadingParts = filterCancelledParts(product);
    this.setState({
      openProductReport: true,
      product,
      successDownloadingParts,
      errorDownloadingParts,
      canceledDownloadingParts
    });
  };
}

export default DownloadPage;
