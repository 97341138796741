import decodeJWT from "jwt-decode";
import axios from "axios";
import authentication from "@kdpw/msal-b2c-react";
import format from "string-format";

import ResponseError from "../../error/ResponseError";
import ConnectionError from "../../error/ConnectionError";
import ValidationError from "../../error/ValidationError";

import {
  CONNECTION_TIMEOUT,
  DOWNLOAD_TIMEOUT,
  CHROME_VERSION_FOR_ABORT,
  FIREFOX_VERSION_FOR_ABORT,
} from "../common/Constants";
/**
 * Timeout function
 * @param {Integer} time (miliseconds)
 * @param {Promise} promise
 */

const { detect } = require("detect-browser");
const browser = detect();

let timeoutVar;

// Check browser version number to use AbortController
export const browser_check_for_abort = () => {
  if (browser.name === "chrome") {
    if (parseFloat(browser.version) >= CHROME_VERSION_FOR_ABORT) {
      return true;
    }
  }
  if (browser.name === "firefox") {
    if (parseFloat(browser.version) >= FIREFOX_VERSION_FOR_ABORT) {
      return true;
    }
  }
  return false;
};

const timeout_promise = async (time, promise) => {
  return new Promise(function (resolve, reject) {
    timeoutVar = setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

export const download_part = async (
  url,
  partSize,
  progressHandler,
  data,
  controller
) => {
  await timeout_promise(
    DOWNLOAD_TIMEOUT,
    fetch(url, {
      // signal is used to break fetch, because if user want to retry before 1 hr timeout signal can abort the operation
      signal: controller.signal,
    })
  )
    .then(async (response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const reader = response.body.getReader();

      // Step 2: get total length, it is 0 since crossing site
      // const contentLength = +response.headers.get('Content-Length');

      // Step 3: read the data
      let receivedLength = 0; // length at the moment
      let chunks = []; // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        progressHandler(receivedLength, partSize);
      }

      // Step 4: concatenate chunks into single Uint8Array
      let chunksAll = new Uint8Array(receivedLength); // (4.1)
      let position = 0;
      for (let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
      }

      if (partSize != position) {
        throw new ValidationError();
      }
      data.value = chunksAll;
    })
    .catch((error) => {
      throw error;
    });
};

export const axiosGet = async (url, config, conn_timeout) => {
  conn_timeout = conn_timeout ? conn_timeout : CONNECTION_TIMEOUT;
  try {
    axios.defaults.timeout = conn_timeout;
    const instance = axios.create({
      baseURL: url,
      timeout: conn_timeout,
    });
    return await instance.get(url, config);
  } catch (error) {
    if (error.response) {
      throw new ResponseError();
    } else if (error.request) {
      throw new ConnectionError();
    } else {
      throw error;
    }
  }
};

/*
Get new access token when the existing one has expired.
New access token is generated when authentication.run() is invoked.
*/
export const refreshAccessToken = async () => {
  const jwt = decodeJWT(authentication.getAccessToken());
  var current_time = Date.now() / 1000;
  var remaining_time = jwt.exp - current_time;
  // Generating access token every 57 minutes
  // Remaining time is a decreasing value from 3600 which means when it goes below 300, 57 mins(3420 secs) has passed.
  if (remaining_time < 180) {
    // Execute authentication and get new access token
    return new Promise((resolve, reject) => {
      authentication.run(() => {
        try {
          // Add the new token information to the header
          axios.defaults.headers.common = {
            Authorization: `bearer ${authentication.getAccessToken()}`,
          };
          // Return/resolve something to continue the execution
          resolve(true);
        } catch {
          reject("Unable to get access token");
        }
      });
    });
  }
};

export const isInternalAccount = () => {
  const jwt = decodeJWT(authentication.getAccessToken());
  if (Array.isArray(jwt.emails)) {
    return jwt.emails[0].endsWith("arm.com");
  } else {
    return jwt.emails.endsWith("arm.com");
  }
};

/*
Wrapper for axios.get function, make use of this function for any axios.get() calls
access token is not refreshed when noAuthHeader is set to true, this flag is used while
making calls to S3. API calls to S3 doesnt require Authorization bearer.
*/
export const myAxiosGet = async (
  url,
  config,
  noAuthHeader = false,
  conn_timeout
) => {
  if (noAuthHeader === true) {
    delete axios.defaults.headers.common["Authorization"];
    const resp = await axiosGet(url, config);
    // re-add Authorization header
    axios.defaults.headers.common = {
      Authorization: `bearer ${authentication.getAccessToken()}`,
    };
    return resp;
  }
  await refreshAccessToken();
  return await axiosGet(url, config, conn_timeout);
};

export const sizeToText = (value) => {
  var result = 0;
  result = value / (1000 * 1000 * 1000); //GB
  if (result > 1) return format("{0}(GB)", result.toFixed(2));
  result = value / (1000 * 1000); //MB
  if (result > 1) return format("{0}(MB)", result.toFixed(2));
  result = value / 1000; //KB
  if (result > 1) return format("{0}(KB)", result.toFixed(2));
  return format("{0}(B)", value);
};
