const getPartName = (part_name, revision, version) => {
  return `${part_name}-${revision}-${version}`;
};

export const getFileName = part => {
  const { part_name, revision, version } = part;
  const extension = "tgz";
  const fileName = `${getPartName(part_name, revision, version)}.${extension}`;
  return fileName;
};

export const getCurrentDate = () => {
  return new Date();
};

export const getAthenaDateTimeFormat = datetime => {
  const year = datetime.getUTCFullYear();
  const month = datetime.getUTCMonth() + 1;
  const day = datetime.getUTCDate();
  const hour = datetime.getUTCHours();
  const minute = datetime.getUTCMinutes();
  const second = datetime.getUTCSeconds();
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const msToTime = duration => {
  var milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
};

/**
 * return Mbps - Mega bit per second
 * @param size byte
 * @param duration millisecond
 */
export const downloadSpeed = (size, duration) => {
  if (duration) {
    size = (size * 8) / Math.pow(2, 20);
    return (size * 1000) / duration;
  } else {
    return 0.0;
  }
};
