import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { SNACKBAR_VARIANT, ERRATA_STEPS } from "../components/common/Constants";
import CustomSnackbar from "../components/common/CustomSnackbar";

import {
  getErrataProductsInfo,
  getErrataUploadUrl,
  updateEffectedProducts,
} from "../services/ProductServices";

class LoadingPopUp extends Component {
  render() {
    const { open } = this.props;
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
}

class ErrataProduct extends Component {
  state = {};

  render() {
    const {
      product,
      versions,
      selectProductHandler,
      seletedVersions,
    } = this.props;
    return (
      <React.Fragment>
        <div className="row" style={{ paddingTop: "10px" }}>
          <div className="col-md-12">
            <b>{product}</b>
          </div>
        </div>
        <div className="row">
          {versions.map((version) => {
            const version_id = version[0];
            const version_revision = version[1];
            const version_version = version[2];
            const labelId = `${product}-${version_id}`;
            return (
              <div className="col-md-3" key={labelId}>
                <Checkbox
                  style={{ marginLeft: "10px" }}
                  id={labelId}
                  checked={this.isChecked(seletedVersions, version_id)}
                  color="primary"
                  onChange={(event) =>
                    selectProductHandler(event, product, version_id)
                  }
                />
                {version_revision}-{version_version}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  isChecked = (seletedVersions, version_id) => {
    for (const idVersion of seletedVersions) {
      if (idVersion === version_id) {
        return true;
      }
    }
    return false;
  };
}

class ErrataPage extends Component {
  state = {
    noticeType: "None",
    noticeTypeError: false,
    documentName: "",
    documentNameError: false,
    documentVersion: "",
    documentVersionError: false,
    platform: undefined,
    platformError: false,
    code: "",
    codeError: false,
    products: [],
    effectedProducts: {},
    effectedProductsError: false,
    selectedFile: null,
    selectedFileError: false,
    snackbarOpen: false,
    snackbarMsg: "",
    snackbarVariant: SNACKBAR_VARIANT.ERROR,
    errataStep: ERRATA_STEPS.INIT,
    uploadInfo: undefined,
    openLoading: false,
  };
  render() {
    const {
      noticeType,
      code,
      products,
      codeError,
      noticeTypeError,
      selectedFileError,
      documentNameError,
      documentVersionError,
      platformError,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
      openLoading,
    } = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <h6>Errata/Product Notification</h6>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-md-12">
                <Divider />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">Notice Type *</div>
              <div className="col-md-3">
                <Select
                  id="demo-simple-select"
                  value={noticeType}
                  onChange={this.noticeTypeHandler}
                  error={noticeTypeError}
                >
                  <MenuItem value="None">Please select one</MenuItem>
                  <MenuItem value="ERRATA">Errata notification</MenuItem>
                  <MenuItem value="PRODUCT">Product notification</MenuItem>
                </Select>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <input
                  id="contained-button-file"
                  type="file"
                  onChange={this.selectFileHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">Name *</div>
              <div className="col-md-3">
                <TextField
                  id="documentName"
                  label="Name"
                  onChange={this.documentNameHandler}
                  error={documentNameError}
                  helperText={documentNameError && "Please input document name"}
                />
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2">Version *</div>
              <div className="col-md-3">
                <TextField
                  id="documentVersion"
                  label="Version"
                  onChange={this.documentVersionHandler}
                  error={documentVersionError}
                  helperText={
                    documentVersionError && "Please input document version"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">Code *</div>
              <div className="col-md-8">
                <TextField
                  id="code"
                  label="Code"
                  onChange={this.productCodeHandler}
                  error={platformError || codeError}
                  helperText={
                    (platformError && "only one platform allows") ||
                    (codeError && "Please input product code(s)")
                  }
                >
                  {code}
                </TextField>
              </div>
              <div className="col-md-2">
                <Button color="primary" onClick={this.getProductsInfo}>
                  Search
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">{this.customList(products)}</div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Button color="secondary">Cancel</Button>
                <Button color="primary" onClick={this.uploadHandler}>
                  Upload
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>

        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={this.handleCloseSnackbar}
          variant={snackbarVariant}
        />
        <LoadingPopUp open={openLoading} />
      </React.Fragment>
    );
  }

  customList = (items) => {
    const { effectedProducts } = this.state;
    return Object.entries(items).map((item) => {
      var seletedVersions = effectedProducts[item[0]];
      if (seletedVersions === undefined) {
        seletedVersions = [];
      }
      return (
        <ErrataProduct
          key={item[0]}
          product={item[0]}
          versions={item[1]}
          selectProductHandler={this.selectProductHandler}
          seletedVersions={seletedVersions}
        />
      );
    });
  };

  noticeTypeHandler = (event) => {
    var { noticeType, errataStep } = this.state;
    if (noticeType !== event.target.value) {
      errataStep = ERRATA_STEPS.INIT;
      noticeType = event.target.value;
    }
    const noticeTypeError = noticeType === "None";
    this.setState({ noticeType, noticeTypeError, errataStep });
  };

  documentNameHandler = (event) => {
    var { documentName, errataStep } = this.state;
    if (documentName !== event.target.value) {
      documentName = event.target.value;
      errataStep = ERRATA_STEPS.INIT;
    }
    const documentNameError = documentName === "" || documentName.trim() === "";
    this.setState({ documentName, documentNameError, errataStep });
  };

  documentVersionHandler = (event) => {
    var { documentVersion, errataStep } = this.state;
    if (documentVersion !== event.target.value) {
      documentVersion = event.target.value;
      errataStep = ERRATA_STEPS.INIT;
    }
    const documentVersionError =
      documentVersion === "" || documentVersion.trim() === "";
    this.setState({ documentVersion, documentVersionError, errataStep });
  };

  productCodeHandler = async (event) => {
    const code = event.target.value.trim().replace(" ", "");
    var codeError = false;
    const productCodes = event.target.value.split(",");
    for (const productCode of productCodes) {
      if (productCode.trim().length < 4) {
        codeError = true;
      }
    }
    this.setState({ code, codeError });
  };

  getProductsInfo = async (event) => {
    const { code, effectedProducts } = this.state;
    var { codeError, platformError, platform } = this.state;
    codeError = false;
    platformError = false;
    const productCodes = code.trim().split(",");
    if (Object.values(effectedProducts).length == 0) {
      platform = undefined;
    }
    for (const productCode of productCodes) {
      if (productCode.trim().length < 4) {
        codeError = true;
        break;
      } else if (
        platform !== undefined &&
        platform !== productCode.trim().substring(0, 4)
      ) {
        platformError = true;
        break;
      }
      platform = productCode.trim().substring(0, 4);
    }
    this.setState({
      products: [],
      codeError,
      platform,
      platformError,
      openLoading: true,
    });
    if (platformError || codeError) {
      this.setState({ openLoading: false });
      return;
    }
    try {
      const products = await getErrataProductsInfo(code);
      this.setState({ products, openLoading: false });
    } catch (error) {
      const snackbarMsg = "can't product info. Please retry!";
      this.setState({ openLoading: false, snackbarMsg, snackbarOpen: true });
    }
  };

  selectProductHandler = (event, product, version_id) => {
    const { effectedProducts } = this.state;
    var versions = effectedProducts[product];
    if (versions === undefined) {
      versions = [];
    }
    this.state.errataStep =
      this.state.errataStep === ERRATA_STEPS.COMPLETED
        ? ERRATA_STEPS.UPLOADED
        : this.state.errataStep;
    if (event.target.checked) {
      effectedProducts[product] = [...versions, version_id];
      this.setState({ effectedProducts });
    } else {
      const tempProducts = versions.filter((item) => item !== version_id);
      effectedProducts[product] = tempProducts;
      this.setState({ effectedProducts });
    }
  };

  selectFileHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      errataStep: ERRATA_STEPS.INIT,
    });
  };

  uploadHandler = async () => {
    this.setState({ openLoading: true });
    if (this.validateInputs()) {
      const snackbarMsg =
        "Validate inputs error. Please review inputs adn retry!";
      this.setState({ openLoading: false, snackbarMsg, snackbarOpen: true });
      return;
    }
    if (this.state.errataStep === ERRATA_STEPS.INIT) {
      const uploadInfo = await this.getUploadUrl();
      if (uploadInfo === undefined) {
        this.setState({ openLoading: false });
        return;
      }
      this.state.uploadInfo = uploadInfo;
      this.state.errataStep = ERRATA_STEPS.HAD_URL;
    }
    this.setState({ openLoading: true });
    if (this.state.errataStep === ERRATA_STEPS.HAD_URL) {
      const signedUrl = this.state.uploadInfo[1];
      if (!(await this.uploadDocumentFile(signedUrl))) {
        this.setState({ openLoading: false });
        return;
      }
      this.state.errataStep = ERRATA_STEPS.UPLOADED;
    }
    this.setState({ openLoading: true });
    if (this.state.errataStep === ERRATA_STEPS.UPLOADED) {
      const noticeId = this.state.uploadInfo[0];
      if (!(await this.updateNoticeInfo(noticeId))) {
        this.setState({ openLoading: false });
        return;
      }
      this.state.errataStep = ERRATA_STEPS.COMPLETED;
    }
    this.setState({ openLoading: false });
    const { history } = this.props;
    history.push("/cart");
  };

  validateInputs = () => {
    const {
      effectedProducts,
      noticeType,
      selectedFile,
      documentName,
      documentVersion,
      platform,
    } = this.state;
    var noticeTypeError,
      selectedFileError,
      documentNameError,
      documentVersionError,
      platformError;

    noticeTypeError = noticeType === "None";
    selectedFileError = selectedFile === undefined || selectedFile === null;
    documentNameError = documentName === "" || documentName.trim() === "";
    documentVersionError =
      documentVersion === "" || documentVersion.trim() === "";
    platformError =
      platform === undefined || platform === "" || platform.trim() === "";

    var effectedProductsError = true;
    for (const versions in Object.values(effectedProducts)) {
      if (versions.length > 0) effectedProductsError = false;
    }

    this.setState({
      noticeTypeError,
      selectedFileError,
      documentNameError,
      documentVersionError,
      platformError,
      effectedProductsError,
    });
    return (
      noticeTypeError ||
      selectedFileError ||
      documentNameError ||
      documentVersionError ||
      platformError ||
      effectedProductsError
    );
  };

  handleCloseSnackbar = () => {
    const snackbarOpen = false;
    this.setState({ snackbarOpen });
  };

  async uploadDocumentFile(signedUrl) {
    const { selectedFile } = this.state;
    const data = new FormData();
    data.append("file", selectedFile);
    try {
      await fetch(signedUrl, {
        method: "PUT",
        body: data,
      });
      return true;
    } catch (error) {
      const snackbarMsg = "can't upoad the document. Please retry!";
      this.setState({ snackbarMsg, snackbarOpen: true });
      return false;
    }
  }

  async getUploadUrl() {
    const { noticeType, documentName, documentVersion, platform } = this.state;
    try {
      return await getErrataUploadUrl(
        noticeType,
        documentName,
        documentVersion,
        platform
      );
    } catch (error) {
      const snackbarMsg = "can't get upload url. Please retry!";
      this.setState({ snackbarMsg, snackbarOpen: true });
    }
  }

  async updateNoticeInfo(noticeId) {
    const { effectedProducts } = this.state;
    try {
      await updateEffectedProducts(noticeId, effectedProducts);
      return true;
    } catch (error) {
      const snackbarMsg = "can't update notice information. Please retry!";
      this.setState({ snackbarMsg, snackbarOpen: true });
      return false;
    }
  }
}

export default withRouter(ErrataPage);
