import React, { Component } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Assignment from "@material-ui/icons/Assignment";
import Autorenew from "@material-ui/icons/Autorenew";

import Table from "./common/table";
import ProgressBar from "./common/progressBar";
import CancelIcon from '@material-ui/icons/Cancel';
import { DOWNLOAD_STATUS } from "./common/Constants";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { browser_check_for_abort } from "../components/util/commonUtil";

class DownloadProductPanel extends Component {
  META_COLUMNS = {
    key: "download",
    key_row: "key",
    columns: [
      {
        path: "key",
        label: "Part Code"
      },
      {
        path: "revision",
        label: "Revision",
        columnStyleWidth: "8%"
      },
      {
        path: "version",
        label: "Version",
        columnStyleWidth: "8%"
      },
      {
        path: "part_size",
        label: "Size",
        columnStyleWidth: "8%",
        content: part => {
          return this.renderSize(part.part_size);
        }
      },
      {
        key: "progress",
        label: "Progress",
        content: part => {
          return (
            <ProgressBar percentage={part.percentage} status={part.status} />
          );
        }
      }
    ]
  };

  constructor(props) {
    super(props);
    this.onBeforeUnload = this.onBeforeUnload.bind(this); //bind function once
  }

  onBeforeUnload(event) {
    // The default browser msg cannot be modified, This feature works only on Chrome
    // Refer: https://www.chromestatus.com/feature/5349061406228480
    event.returnValue = "Test";
    var dontleave = "Download in progress..";
    return dontleave;
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload, false);
  }

  renderSize(sizeInByte) {
    let size = sizeInByte === undefined ? 0 : sizeInByte;
    let tmpSize = this._change_unit(size);
    if (tmpSize < 1) {
      return `${size} Bytes`;
    }
    size = tmpSize;
    tmpSize = this._change_unit(size);
    if (tmpSize < 1) {
      size = Number(size.toFixed(2));
      return `${size} KB`;
    }
    size = tmpSize;
    tmpSize = this._change_unit(size);
    if (tmpSize < 1) {
      size = Number(size.toFixed(2));
      return `${size} MB`;
    }
    size = tmpSize;
    size = Number(size.toFixed(2));
    return `${size} GB`;
  }

  _change_unit(size) {
    return size / 1024;
  }

  state = {
    product: {},
    downloadingPartIndex: 0
  };

  render() {
    const { product, showRedownload, showCancelButton } = this.props;
    this.product = product;
    const parts = product.parts !== undefined ? product.parts : [];
    const downloadingStatus = product.status;

    return (
      <ExpansionPanel disabled={downloadingStatus === undefined}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className="col-md-4">{product.product_name}</div>
          <div className="col-md-2">{product.product_code}</div>
          <div className="col-md-5">{this.renderProgressBarProductLevel()}</div>
          <div className="col-md-1">
            <Assignment onClick={this.downloadReportHandler} />
            {showRedownload &&
              (<Tooltip title="Retry downloads">
                <Autorenew onClick={this.retryDownloadErrorProduct} />
              </Tooltip>)}
            {browser_check_for_abort() && showCancelButton && (<Tooltip title="Cancel Downloads">
              <CancelIcon onClick={this.cancelDownloads} />
            </Tooltip>)}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Table metadata={this.META_COLUMNS} data={parts} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  renderProgressBarProductLevel() {
    const { product } = this.props;
    const downloadingStatus = product.status;
    const percentage = product.progress;
    if (downloadingStatus === DOWNLOAD_STATUS.SUCCESS) {
      window.removeEventListener("beforeunload", this.onBeforeUnload, false);
    }
    return (
      <div style={{ width: "100%" }}>
        <ProgressBar percentage={percentage} status={downloadingStatus} />
      </div>
    );
  }

  retryDownloadErrorProduct = e => {
    e.stopPropagation();
    const product = this.product;
    const { retryDownloadErrorProductHandler } = this.props;
    retryDownloadErrorProductHandler(product);
  };

  cancelDownloads = e => {
    e.stopPropagation();
    const { cancelDownloadHandler } = this.props;
    cancelDownloadHandler(true);
  };

  downloadReportHandler = e => {
    e.stopPropagation();
    const product = this.product;
    const { openProductReport } = this.props;
    openProductReport(product);
  };
}

export default DownloadProductPanel;
