import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

function ShowReadMePage(props) {
  const scroll = "paper";
  const { open, content, handleClose, dialogLoading } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogContent>
        {dialogLoading ? <CircularProgress size={25} /> : ""}
        <div style={{ whiteSpace: "pre-wrap" }}>
          <pre>{content}</pre>{" "}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowReadMePage;
