// Keep the QA_HOSTNAME for now
const QA_HOSTNAME = "ds-beta.arm.com";
const PROD_HOSTNAME = "designstart-beta.arm.com";
const DEV_HOSTNAME = "localhost";
const DEV_PORT = "3000";

export const QA_SERVER = "https://athena-qa.arm.com";
export const QA_EXT_SERVER = "https://athena-qa-ext.arm.com";
export const PROD_SERVER = "https://athena.arm.com";
export const PROD_EXT_SERVER = "https://athena-ext.arm.com";
export const DEV_SERVER = "http://login15.nahpc.arm.com:9500";
export const INTERNAL_HOSTNAME = "https://athena-blue.arm.com/healthcheck"

export const REPORT_SERVVER =
  "http://athena-report-backend.eu-west-1.elasticbeanstalk.com";
// "http://localhost:8080";

//Define End points
export const PRODUCTS_ENDPOINT = "/designstartapi/searchds/all_products/";
export const SIGNED_URL_ENDPOINT = "/designstartapi/parts/ext/get_signed_url/";
export const LIST_PARTS_ENDPOINT = "/designstartapi/parts/ext/get_list_parts/";
export const DOC_PART_CONTENT_ENDPOINT =
  "/designstartapi/parts/ext/get_doc_part_content/";
export const PACKAGE_TYPE_ENDPOINT =
  "/designstartapi/parts/ext/get_package_type/";
export const GET_HEALTH_CHECK_ENDPOINT = "/healthcheck";

// report endpoints
export const GET_SAPCUSTOMER = "/api/report/sapcustomers";
export const GET_USENAME_BY_IDOC = "/api/report/users/sapcode/{}";
export const GET_DOWNLOAD_HISTORY_REPORT = "/api/report/history/download";
export const GET_DOWNLOAD_HISTORY_PRODUCT_REPORT =
  "/api/report/history/summary";
export const GET_TECHNOLOGY_REPORT = "/api/report/technologies";
export const GET_FOUNDRY_REPORT = "/api/report/foundries";
export const GET_PROCESS_REPORT = "/api/report/processes";
export const GET_GROUP_REPORT = "/api/report/groups";
export const GET_FAMILY_REPORT = "/api/report/families";
export const GET_DOWNLOADED_TECHNOLOGY = "/api/report/technologies/downloaded";
export const GET_DOWNLOADED_FOUNDRY = "/api/report/foundries/downloaded";
export const GET_DOWNLOADED_PROCESS = "/api/report/processes/downloaded";
export const GET_DOWNLOADED_GROUP = "/api/report/groups/downloaded";
export const GET_DOWNLOADED_FAMILY = "/api/report/families/downloaded";

// errata enpoints
export const ERRATA_GET_PRODUCT_INFO =
  "/designstartapi/errata/int/get_products_versions";
export const ERRATA_GET_UPLOAD_URL =
  "/designstartapi/errata/int/errata_upload_url";
export const ERRATA_UPDATE_PRODUCTS =
  "/designstartapi/errata/int/errata_update_products";
export const HAS_ERRATA_NOTICES_BY_PRODUCT =
  "/designstartapi/errata/has_errata_notices";
export const GET_ERRATA_NOTICES_BY_PRODUCT =
  "/designstartapi/errata/get_errata_notices";
export const GET_ERRATA_NOTICE_DOWNLOAD =
  "/designstartapi/errata/download_notice";

const hostname = window.location.hostname;

let server,
  b2c_instance,
  b2c_tenant,
  b2c_clientid,
  b2c_signinpolicy,
  b2c_scopes,
  b2c_redirecturl,
  b2c_post_logout_redirecturl;

b2c_redirecturl = "https://" + hostname;
if (
  (hostname === PROD_HOSTNAME) &
  (process.env.REACT_APP_QA_BUILD !== "True")
) {
  b2c_instance = "https://account.arm.com/";
  b2c_tenant = "armb2c.onmicrosoft.com";
  b2c_clientid = "8974494a-1ea2-4535-88ad-4171648d491f";
  b2c_signinpolicy = "b2c_1a_arm_accounts.susi";
  // the scopes you want included in the access token
  b2c_scopes = [
    "https://armb2c.onmicrosoft.com/designstart-beta/Read",
    "openid",
    "offline_access ",
  ];
  // optional, the URI to redirect to after logout
  b2c_post_logout_redirecturl = "http://myapp.com";
  server = "PROD";
}
// Pointing to QA for both QA and DEV environments
else {
  b2c_instance = "https://account.arm.com/";
  b2c_tenant = "armb2ctest.onmicrosoft.com";
  b2c_clientid = "2fdf3e59-6149-4a8f-833a-af34f9188813";
  b2c_signinpolicy = "b2c_1_arm_pdh_ds"; //default policy to test consistency
  // Keep this for future release "b2c_1a_arm_pdh_ds.susi";
  //b2c_signinpolicy = "b2c_1a_arm_pdh_ds.susi";
  b2c_scopes = [
    "https://armb2ctest.onmicrosoft.com/designstart-beta/Read",
    "openid",
    "offline_access",
  ];
  // optional, the URI to redirect to after logout
  b2c_post_logout_redirecturl = "http://myapp.com";
  if (hostname === DEV_HOSTNAME) {
    b2c_redirecturl = "http://" + hostname + ":" + DEV_PORT;
    server = "DEV";
  } else {
    server = "QA";
  }
}

export const PLUGIN_HANDLER =
  "athena.arm.com://getcode?source=web&action=plugin_download&";

export const SERVER_TYPE = server;
export const FILTER_FOUNDRY_TITLE = "Foundries";
export const FILTER_TECHNOLOGY_TITLE = "Technologies";
export const FILTER_PROCESS_TITLE = "Processes";
export const FILTER_GROUPS_TITLE = "Product Groups";
// export const FILTER_FAMILY_TITLE = "Family";

export const SAVE_CART_ENDPOINT = "/designstartapi/searchds/save_cart/";
export const GET_CART_ENDPOINT = "/designstartapi/searchds/get_cart/";
export const UPDATE_DOWNLOAD_STATUS =
  "/designstartapi/downloadhistory/update_downloadhistory/";
export const UPDATE_PRODUCT_STATUS =
  "/designstartapi/downloadhistory/update_product_status/";
export const B2C_INSTANCE = b2c_instance;
export const B2C_TENANT = b2c_tenant;
export const B2C_CLIENTID = b2c_clientid;
export const B2C_SIGNINPOLICY = b2c_signinpolicy;
export const B2C_SCOPES = b2c_scopes;
export const B2C_POST_LOGOUT_REDIRECTURL = b2c_post_logout_redirecturl;

// download status
export const DOWNLOAD_STATUS = {
  INIT: "NotStarted",
  DOWNLOADING: "DOWNLOADING",
  SUCCESS: "Completed",
  ERROR: "ERROR",
};

export const B2C_REDIRECT_URL = b2c_redirecturl;

export const SNACKBAR_VARIANT = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const ERRATA_STEPS = {
  INIT: "INITT",
  HAD_URL: "HAD_UPLOADED_URL",
  UPLOADED: "UPLOADED",
  COMPLETED: "COMPLETED",
};

export const RESPONSE_ERROR_MSG =
  "Server Error! Please try again and report the issue";

export const CONNECTION_ERROR_MSG =
  "There is network connection issue. Please check your connect";

export const CANCEL_DOWNLOADS_MSG =
  "Cancelling downloads, please wait and press Retry button to resume downloads";

export const SAVECART_SUCCESS_MSG = "Cart saved";
export const SAVECART_ERROR_MSG =
  "Oops! Could not save products in Cart. Please try again and report the issue";

export const GETCART_ERROR_MSG =
  "Could not get products in carts. Please refresh and/or report the issue";

export const CHECKSUM_ERROR_MSG = "Checksum error. Try to redownload";

export const GATEWAY_TIMEOUT_MSG = "Gateway Time-out";

export const DOWNLOAD_ITEMS = {
  PART_LEVEL: "Part Based",
  PRODUCT_LEVEL: "Product Based",
};

// Supported Firefox and Chrome for AbortController
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController#Browser_compatibility
export const CHROME_VERSION_FOR_ABORT = 66;
export const FIREFOX_VERSION_FOR_ABORT = 57;

// 2 minus
export const CONNECTION_TIMEOUT = 2 * 60 * 1000;
export const REPORT_TIMEOUT = 30 * 60 * 1000;
export const DOWNLOAD_TIMEOUT = 60 * 60 * 1000; //1 hour
export const HISTORY_TIMEOUT = 15 * 1000; //15 seconds
export const SIGNED_URL_TIMEOUT = 30 * 1000; //30 seconds

export const DOWNLOAD_TRY_NUMBER = 5;

// GB
export const MAX_ZIP_SIZE = 2 * 1024 * 1024 * 1024;
export const ZIP_FILE_NAME_FORMAT = "{0}{1}.zip";
export const ZIP_SPLIT_NAME_FORMAT = ".p{0}";
export const ZIP_FINAL_FILE_NAME_FORMAT = "{0}.final.zip";

export const DOWNLOAD_LOG_NAME = "download{0}.log";
export const SUMMARY_LOG_NAME = "summary.log";

export const UNTAR_UNIX_NAME = "extract_unix.sh";
export const UNTAR_WIN_NAME = "extract_win.bat";
export const README_NAME = "README.txt";
export const UNTAR_UNIX_CMD = 'for z in {0}*.tgz; do tar xvf "$z"; done';
export const README_TEXT =
  "****** Please do not rename the downloaded .zip files ******\n\n" +
  "To extract your downloaded products, download the extractor script from the following path:\n" +
  "Linux - https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e/file/949836961218\n" +
  "Windows - https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e/file/950850256532\n\n" +
  "Run dsextractor --help in your command line prompt for detail usage\n";

export const PRODUCT_SESSION_KEY = "PRODUCT_SESSION";

export const SESSION_SAP_CUSTOMER = "SESSION_SAP_CUSTOMER";
export const SESSION_USERNAME = "SESSION_USERNAME";
export const SESSION_FOUNDRY = "SESSION_FOUNDRY";
export const SESSION_TECHNOLOGY = "SESSION_TECHNOLOGY";
export const SESSION_PROCCESS = "SESSION_PROCESS";
export const SESSION_GROUP = "SESSION_GROUP";
export const SESSION_FAMILY = "SESSION_FAMILY";

export const PRODUCT_LEVEL = "Product level";
export const PART_LEVEL = "Part level";
