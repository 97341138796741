import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

export default class DropBox extends Component {
  render() {
    const {
      compId,
      label,
      values,
      itemKey = "id",
      itemText = "name",
      selectedValue = "",
      onChangeHandler,
      loading = false
    } = this.props;
    return (
      <React.Fragment>
        <div className="item-filter">
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink htmlFor={compId}>
              {label}
            </InputLabel>
            <NativeSelect
              value={selectedValue}
              onChange={onChangeHandler}
              disabled={loading}
            >
              <option value="">None</option>
              {values.map((item, i) => (
                <option key={item[itemKey]} value={item[itemKey]}>
                  {item[itemText]}
                </option>
              ))}
            </NativeSelect>
            {loading ? <LinearProgress /> : ""}
          </FormControl>
        </div>
      </React.Fragment>
    );
  }
}
