import DesignStartError from "./DesignStartError";
import { CONNECTION_ERROR_MSG } from "../components/common/Constants";

class ConnectionError extends DesignStartError {
  constructor() {
    super(CONNECTION_ERROR_MSG);
  }
}

export default ConnectionError;
