import React, { Component } from "react";
import { Markup } from 'interweave';

class AccordionItem extends Component {
    constructor() {
        super();
        this.state = {
            active: false
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
            active: !this.state.active,
            className: "active"
        });
    }
    render() {
        const activeClass = this.state.active ? "active" : "inactive";
        const question = this.props.details;
        return (
            <div>
                <div className={activeClass} onClick={this.toggle}>
                    <span className="summary">{question.summary}</span>
                </div>
                <div className={activeClass}>
                    <span className="folding-pannel">
                        <Markup content={question.answer} />
                    </span>
                </div>
            </div>
        );
    }
}

export default AccordionItem;
