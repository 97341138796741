import * as Constants from "../components/common/Constants";
import * as CommonUtil from "../components/util/commonUtil";

let server = Constants.PROD_SERVER;

export const healthCheck = async () => {
  let first_server, second_server;
  if (Constants.SERVER_TYPE === "PROD") {
    first_server = Constants.PROD_SERVER;
    second_server = Constants.PROD_EXT_SERVER;
  } else if (Constants.SERVER_TYPE === "QA") {
    first_server = Constants.QA_SERVER;
    second_server = Constants.QA_EXT_SERVER;
  } else {
    first_server = Constants.DEV_SERVER;
    second_server = Constants.DEV_SERVER;
  }

  const config = {
    params: {},
  };
  try {
    server = first_server;
    await CommonUtil.myAxiosGet(
      second_server + Constants.GET_HEALTH_CHECK_ENDPOINT,
      config
    );
    server = second_server;
  } catch (erro) {
    server = first_server;
  }
  return server;
};

export const getProducts = async (
  selectedFoundries = [],
  selectedTechnologies = [],
  selectedProcesses = [],
  selectedGroups = [],
  selectedFamilies = [],
  searchMode = true,
  isEntitled = true,
  athena = true
) => {
  const _params = {
    params: {
      foundry: selectedFoundries.join(","),
      technology: selectedTechnologies.join(","),
      process: selectedProcesses.join(","),
      group: selectedGroups.join(","),
      family: selectedFamilies.join(","),
      search_mode: searchMode,
      isEntitled: isEntitled,
      athena: athena,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.PRODUCTS_ENDPOINT,
    _params
  );

  const { count: totalProduct, records: products, metadata } = response.data;
  const {
    foundry: foundries,
    process: processes,
    technology: technologies,
    productgroup: groups,
    families,
  } = metadata;
  return {
    searchMode,
    totalProduct,
    products,
    foundries,
    selectedFoundries,
    processes,
    selectedProcesses,
    technologies,
    selectedTechnologies,
    groups,
    selectedGroups,
    families,
    selectedFamilies,
  };
};

export const updateProductStatus = (productCode, oldStatus, newStatus) => {
  const config = {
    params: {
      product_code: productCode,
      old_status: oldStatus,
      new_status: newStatus,
    },
  };

  try {
    CommonUtil.myAxiosGet(server + Constants.UPDATE_PRODUCT_STATUS, config);
  } catch (error) {}
};

export const updateHistory = (part) => {
  const {
    signatures,
    download_percent,
    status,
    completed_time,
    duration,
    part_size,
    speed,
    part_name,
  } = part;
  const config = {
    params: {
      signature: signatures,
      download_percent,
      status,
      completed_time,
      duration,
      size: part_size,
      speed,
      product_code: part_name.substring(0, 9),
    },
  };
  CommonUtil.myAxiosGet(
    server + Constants.UPDATE_DOWNLOAD_STATUS,
    config,
    false,
    Constants.HISTORY_TIMEOUT
  ).catch((erro) => {});
};

export const saveCart = async (products) => {
  const config = {
    params: {
      products: products.join(";"),
    },
  };
  // TODO: it must be POST
  await CommonUtil.myAxiosGet(server + Constants.SAVE_CART_ENDPOINT, config);
};

// incart session
const INCART_PRDUCT_KEY = "INCART_PRODUCTS";

export const getCart = async () => {
  let cartItems = getIncartProducts();

  if (cartItems.length) return { cartItems };

  const config = {
    params: {},
  };

  const response = await CommonUtil.myAxiosGet(
    server + Constants.GET_CART_ENDPOINT,
    config
  );
  cartItems = response.data.cartItems;
  storeIncartProducts(cartItems);
  return response.data;
};

export const getIncartProducts = () => {
  const products = sessionStorage.getItem(INCART_PRDUCT_KEY);
  return products ? JSON.parse(products) : [];
};

export const storeIncartProducts = (products) => {
  sessionStorage.setItem(INCART_PRDUCT_KEY, JSON.stringify(products));
};

/*
returned data consists of checksum, etag, part_size, signatures, and url (signed url)
 */
export const signedUrl = async (part) => {
  const { part_name, revision, version } = part;
  const config = {
    params: { key: part_name, revision: revision, version: version },
  };
  return await CommonUtil.myAxiosGet(
    server + Constants.SIGNED_URL_ENDPOINT,
    config,
    false,
    Constants.SIGNED_URL_TIMEOUT
  );
};

export const getParts = async (code) => {
  const limit = 50000;
  const offset = 0;
  const config = {
    params: { code, limit, offset },
  };
  return await CommonUtil.myAxiosGet(
    server + Constants.LIST_PARTS_ENDPOINT,
    config
  );
};

export const getReadMeContent = async (product_code) => {
  const config = {
    params: { code: product_code },
  };
  return await CommonUtil.myAxiosGet(
    server + Constants.DOC_PART_CONTENT_ENDPOINT,
    config
  );
};

export const getPackageType = async (product) => {
  const config = {
    params: {
      product: product.product_code,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.PACKAGE_TYPE_ENDPOINT,
    config
  );
  const { results } = response.data;
  const { packagetype } = results;
  return packagetype;
};

export const getErrataProductsInfo = async (code) => {
  const config = {
    params: {
      code: code.trim().replace(" ", ""),
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.ERRATA_GET_PRODUCT_INFO,
    config
  );
  return response.data;
};

export const hasErrataNoticesByProduct = async (product_codes) => {
  const config = {
    params: {
      product_codes: product_codes,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.HAS_ERRATA_NOTICES_BY_PRODUCT,
    config
  );
  return response.data;
};

export const getErrataNoticesByProduct = async (product) => {
  const config = {
    params: {
      product: product.product_code,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.GET_ERRATA_NOTICES_BY_PRODUCT,
    config
  );
  return response.data;
};

export const getErrataUploadUrl = async (type, name, version, platform) => {
  const config = {
    params: {
      type: type,
      name: name,
      version: version,
      platform: platform,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.ERRATA_GET_UPLOAD_URL,
    config
  );
  return response.data;
};

export const updateEffectedProducts = async (noticeId, effectedProducts) => {
  const config = {
    params: {
      noticeId: noticeId,
      products: effectedProducts,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.ERRATA_UPDATE_PRODUCTS,
    config
  );
  return response.data;
};

export const downloadErrataNotice = async (key) => {
  const config = {
    params: {
      key,
    },
  };
  const response = await CommonUtil.myAxiosGet(
    server + Constants.GET_ERRATA_NOTICE_DOWNLOAD,
    config
  );
  return response.data;
};
