import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import LinearProgress from "@material-ui/core/LinearProgress";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  }
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { count, page, rowsPerPage, theme, loading } = this.props;

    return (
      <React.Fragment>
        <IconButton
          style={{ margin: "4px" }}
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          style={{ margin: "4px" }}
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          style={{ margin: "4px" }}
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          style={{ margin: "4px" }}
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </React.Fragment>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  // count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(TablePaginationActions);

class TableHeader extends Component {
  renderHeaderCell = column => {
    return column.headComponent ? column.headComponent() : column.label;
  };

  render() {
    return (
      <thead className="thead-ligh">
        <tr>
          {this.props.columns.map(column => {
            return (
              <th
                key={column.key_col || column.path || column.key}
                style={{
                  width: column.columnStyleWidth,
                  verticalAlign: "middle"
                }}
              >
                {this.renderHeaderCell(column)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

class TableBody extends Component {
  state = {
    index: 0
  };
  renderCell = (item, column) => {
    return column.content ? column.content(item) : item[column.path];
  };

  createKey = (item, column) => {
    let key = "";
    if (item[column.key_col] !== undefined) {
      key = item[column.key_col];
    } else if (item[column.path] !== undefined) {
      key = item[column.path];
    } else {
      key = column.key;
    }
    this.state.index += 1;
    return key + this.state.index;
  };

  showLoading = () => {
    const { loading } = this.props;
    return (
      loading && (
        <tr
          style={{
            display: "table",
            width: "100%",
            tableLayout: "fixed"
          }}
        >
          <td colSpan="100">
            <LinearProgress />
          </td>
        </tr>
      )
    );
  };

  render() {
    const { data, metadata, page, rowsPerPage, loading = false } = this.props;
    const { key_row, columns } = metadata;
    return (
      <React.Fragment>
        <tbody>
          {this.showLoading()}
          {data.length === 0 && !loading && (
            <tr colSpan={columns.length} className="hidden-table">
              <td
                style={{
                  textAlign: "center",
                  verticalAlign: "middle"
                }}
              >
                <p />
                No Data
              </td>
            </tr>
          )}
          {data.map(item => {
            return (
              <tr key={item[key_row]}>
                {columns.map(column => (
                  <td
                    key={this.createKey(item, column)}
                    style={{
                      width: column.columnStyleWidth,
                      verticalAlign: "left"
                    }}
                  >
                    {this.renderCell(item, column)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  select: {
    paddingLeft: 8,
    paddingRight: 28
  }
});

class PagingTable extends Component {
  render() {
    const { classes } = this.props;
    const {
      metadata,
      data,
      loading,
      disablePagingBar,
      table_id,
      page,
      rowsPerPage,
      onChangePageHandler,
      totalRecords
    } = this.props;

    return (
      <React.Fragment>
        <table id={table_id}>
          <TableHeader columns={metadata.columns} />
          <TableBody
            metadata={metadata}
            data={data}
            rowsPerPage={rowsPerPage}
            page={page}
            loading={loading}
          />
          {!disablePagingBar && (
            <tfoot>
              <tr>
                <TablePagination
                  classes={{
                    select: classes.select
                  }}
                  // rowsPerPageOptions={pageOpts}
                  count={totalRecords}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  loading={loading}
                  SelectProps={{
                    native: true
                  }}
                  onChangePage={onChangePageHandler}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </tr>
            </tfoot>
          )}
        </table>
      </React.Fragment>
    );
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      page: 0,
      rowsPerPage: Number.parseInt(event.target.value)
    });
  };
}

PagingTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PagingTable);
