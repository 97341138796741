import format from "string-format";
import {
  ZIP_FILE_NAME_FORMAT,
  ZIP_SPLIT_NAME_FORMAT,
  DOWNLOAD_STATUS,
  ZIP_FINAL_FILE_NAME_FORMAT
} from "../common/Constants";

export default function getZipFileName(product, zipNumber) {
  const { product_code } = product;
  let fileName = "";
  if (zipNumber === 0) {
    if (product["status"] === DOWNLOAD_STATUS.SUCCESS) {
      fileName = format(ZIP_FILE_NAME_FORMAT, product_code);
    } else {
      fileName = format(
        ZIP_FILE_NAME_FORMAT,
        product_code,
        format(ZIP_SPLIT_NAME_FORMAT, ++zipNumber)
      );
    }
  } else {
    if (product["status"] === DOWNLOAD_STATUS.SUCCESS) {
      fileName = format(ZIP_FINAL_FILE_NAME_FORMAT, product_code);
    } else {
      fileName = format(
        ZIP_FILE_NAME_FORMAT,
        product_code,
        format(ZIP_SPLIT_NAME_FORMAT, zipNumber)
      );
    }
  }

  product["zipNumber"] = zipNumber;
  return { productCode: product_code, fileName };
}

export function filterDownloadingParts(product, status, groupNumber) {
  let parts = product.parts !== undefined ? product.parts : [];
  if (groupNumber !== undefined) {
    parts = parts.filter(p => p.group === groupNumber);
  }
  return parts.filter(p => p.status === status);
}

export function filterSuccessParts(product, groupNumber) {
  return filterDownloadingParts(product, DOWNLOAD_STATUS.SUCCESS, groupNumber);
}

export function filterFailureParts(product, groupNumber) {
  return filterDownloadingParts(product, DOWNLOAD_STATUS.ERROR, groupNumber);
}

export function filterCancelledParts(product, groupNumber) {
  let parts = product.parts !== undefined ? product.parts : [];
  if (groupNumber !== undefined) {
    parts = parts.filter(p => p.group === groupNumber);
  }
  return parts.filter(p => (p.status === undefined));
}

export function generateTextDownloadReport(product, groupNumber) {
  const successParts = filterSuccessParts(product, groupNumber);
  const failureParts = filterFailureParts(product, groupNumber);
  const cancelledParts = filterCancelledParts(product, groupNumber);
  const successNumber = successParts.length;
  const failureNumber = failureParts.length;
  const cancelledNumber = cancelledParts.length;
  let report = format("Total parts: {0}\n", successNumber + failureNumber + cancelledNumber);
  report += format("Cancelled parts: {0}\n", cancelledNumber);
  report += "Failure parts:\n";
  report += format(" Total: {0}\n", failureNumber);
  report += generateListPartsReport(failureParts);
  report += "Success parts:\n";
  report += format(" Total: {0}\n", successNumber);
  report += generateListPartsReport(successParts);

  return report;
}

function generateListPartsReport(parts) {
  if (parts.length === 0) return "";
  let report = " Parts:\n";
  for (const part of parts) {
    const { part_name, revision, version, description } = part;
    report += format("  - Code: {}-{}-{}\n", part_name, revision, version);
    report += format("    Description: {}\n", description);
  }
  return report;
}
