import React, { Component } from "react";

class EntitlementSwitch extends Component {
  render() {
    const { isEntitled = true, onChangeHandler } = this.props;
    return (
      <React.Fragment>
        <div
          className="custom-control custom-switch"
          style={{ margin: "12px" }}
        >
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitch1"
            checked={isEntitled}
            onChange={event => onChangeHandler(event.target.checked)}
            disabled
          />
          <label className="custom-control-label" htmlFor="customSwitch1">
            <b>Licensed products</b>
          </label>
        </div>
      </React.Fragment>
    );
  }
}

export default EntitlementSwitch;
