import React, { Component } from "react";

import isEnableDisplay from "./common/ulti";
import PagingTable from "./common/PagingTable";

class DownloadHistoryTable extends Component {
  META_COLUMNS = {
    key: "sapId",
    key_row: "sapId",
    columns: [
      {
        path: "sapName",
        label: "SAP Name",
        columnStyleWidth: "20%",
      },
      {
        path: "userName",
        label: "Username",
        columnStyleWidth: "20%",
      },
      {
        path: "productCode",
        label: "Product",
        columnStyleWidth: "15%",
      },
      {
        path: "partCode",
        label: "Part",
        columnStyleWidth: "25%",
      },
      {
        key: "dateCreated",
        path: "dateCreated",
        label: "Download Date",
        columnStyleWidth: "20%",
      },
      {
        path: "packageType",
        label: "Type",
        columnStyleWidth: "5%",
      },
    ],
  };

  state = {
    open: false,
  };

  render() {
    const {
      histories,
      page,
      rowsPerPage,
      totalRecords,
      onChangePageHandler,
      loading,
    } = this.props;
    console.log("DownloadHistoryTable -> render -> histories", histories);
    console.log("DownloadHistoryTable -> render -> rowsPerPage", rowsPerPage);
    return (
      <React.Fragment>
        <PagingTable
          metadata={this.META_COLUMNS}
          data={histories}
          loading={loading}
          table_id="DownloadHistoryTable"
          page={page}
          rowsPerPage={rowsPerPage}
          totalRecords={totalRecords}
          onChangePageHandler={onChangePageHandler}
        />
      </React.Fragment>
    );
  }
}

export default DownloadHistoryTable;
