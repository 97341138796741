import { Component } from "react";

class DesignStartError extends Component {
  state = { message: "" };
  constructor(message) {
    super();
    this.state.message = message;
  }
}

export default DesignStartError;
