import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import JSZip from "jszip";
import format from "string-format";

import "./App.css";
import "./components/css/pdh_app_v2.35.0.0.css";
import "./components/css/pdh_footer_style.cors_v2.35.0.0.css";
import "./components/css/pdh_main.css";
import "./components/css/pdh_usermenu_v2.35.0.0.css";
import "./components/css/accordion.css";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import HeaderBar from "./components/navigation/HeaderBar";
import FooterBar from "./components/navigation/FooterBar";
import CartPage from "./pages/CartPage";
import MainProductPage from "./pages/mainProductPage";
import SubproductPage from "./pages/subProductPage";
import DownloadPage from "./pages/downloadPage";
import SupportPage from "./pages/supportPage";
import FAQPage from "./pages/faqPage";
import CustomSnackbar from "./components/common/CustomSnackbar";
import ErrataPage from "./pages/ErrataPage";

import {
  getProducts,
  getParts,
  saveCart,
  getCart,
  updateHistory,
  storeIncartProducts,
  signedUrl,
  updateProductStatus,
} from "./services/ProductServices";

import {
  getFileName,
  getCurrentDate,
  downloadSpeed,
  msToTime,
  getAthenaDateTimeFormat,
} from "./components/util/partUtil";

import getZipFileName, {
  generateTextDownloadReport,
} from "./components/util/ProductUtil";

import {
  DOWNLOAD_STATUS,
  SAVECART_SUCCESS_MSG,
  SAVECART_ERROR_MSG,
  GETCART_ERROR_MSG,
  CONNECTION_ERROR_MSG,
  SNACKBAR_VARIANT,
  DOWNLOAD_ITEMS,
  DOWNLOAD_TRY_NUMBER,
  README_NAME,
  README_TEXT,
  MAX_ZIP_SIZE,
  DOWNLOAD_LOG_NAME,
  SUMMARY_LOG_NAME,
  RESPONSE_ERROR_MSG,
  GET_DOWNLOADED_TECHNOLOGY,
  CANCEL_DOWNLOADS_MSG,
  CHROME_VERSION_FOR_ABORT,
  FIREFOX_VERSION_FOR_ABORT,
  PLUGIN_HANDLER,
} from "./components/common/Constants";
import ResponseError from "./error/ResponseError";
import DesignStartError from "./error/DesignStartError";
import HistoryPage from "./pages/HistoryPage";
import {
  download_part,
  axiosGet,
  browser_check_for_abort,
} from "./components/util/commonUtil";

class App extends Component {
  state = {
    openAnnoucement: true,
    inCartProducts: [],
    downloadingProduct: {},
    downloadingProducts: [],
    downloadingProductIndex: 0,
    downloadingPart: {},
    downloadingParts: [],
    downloadingPartIndex: 0,
    snackbarOpen: false,
    snackbarMsg: SAVECART_SUCCESS_MSG,
    snackbarVariant: SNACKBAR_VARIANT.SUCCESS,
    isEntitled: true,
    downloadMode: DOWNLOAD_ITEMS.PART_LEVEL,
    zip: null,
    controller: browser_check_for_abort() ? new AbortController() : false,
  };

  async componentDidMount() {
    try {
      const { cartItems } = await getCart();
      this.setState({
        inCartProducts: cartItems,
      });
    } catch (error) {
      if (error instanceof ResponseError) {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: GETCART_ERROR_MSG,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: CONNECTION_ERROR_MSG,
          snackbarOpen: true,
        });
      }
    }
  }

  render() {
    const {
      openAnnoucement,
      inCartProducts,
      downloadingProducts,
      downloadingParts,
      downloadingPartIndex,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
      isEntitled,
      ...others
    } = this.state;

    return (
      <React.Fragment>
        <HeaderBar selected={inCartProducts} />
        <Switch>
          <Route
            path="/cart"
            render={(props) => (
              <CartPage
                inCartProducts={inCartProducts}
                removeFromCartHandler={this.removeFromCartHandler}
                removeAllFromCartHandler={this.removeAllFromCartHandler}
                downloadAllHandler={this.downloadAllHandler}
                pluginDownloadHandler={this.pluginDownloadHandler}
                saveCartHandler={this.saveCartHandler}
                handleCloseSnackbar={this.handleCloseSnackbar}
                snackbarOpen={snackbarOpen}
                snackbarMsg={snackbarMsg}
                snackbarVariant={snackbarVariant}
                {...props}
              />
            )}
          />
          <Route
            path="/products/logic_ip/:groupId/:groupName/:foundryId/:foundryName/:familyId/:familyName/:processId/:processName/:totalMembers"
            render={(props) => (
              <SubproductPage
                inCartProducts={inCartProducts}
                onSelectClickHandler={this.onSelectClickHandler}
                licensedProductHandler={this.licensedProductHandler}
                isEntitled={isEntitled}
                {...props}
              />
            )}
          />
          <Route
            exact={true}
            path="/products"
            render={(props) => (
              <MainProductPage
                openAnnoucement={openAnnoucement}
                inCartProducts={inCartProducts}
                onSelectClickHandler={this.onSelectClickHandler}
                licensedProductHandler={this.licensedProductHandler}
                isEntitled={isEntitled}
                {...props}
              />
            )}
          />
          <Route
            exact={true}
            path="/download/history"
            render={(props) => <HistoryPage />}
          />
          <Route
            exact={true}
            path="/download"
            render={(props) => (
              <DownloadPage
                products={downloadingProducts}
                retryDownloadErrorProductHandler={
                  this.retryDownloadErrorProductHandler
                }
                cancelDownloadHandler={this.cancelDownloadHandler}
                handleCloseSnackbar={this.handleCloseSnackbar}
              />
            )}
          />
          <Route
            exact={true}
            path="/products/s"
            render={(props) => <div> {this.loadQueryString(props)} </div>}
          />
          <Route
            exact={true}
            path="/errata"
            render={(props) => <ErrataPage />}
          />
          <Route
            exact={true}
            path="/support"
            render={(props) => <SupportPage />}
          />
          <Route exact={true} path="/faq" render={(props) => <FAQPage />} />
          <Redirect from="/" to="/products" />
        </Switch>
        <FooterBar />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={this.handleCloseSnackbar}
          variant={snackbarVariant}
        />
        <PluginDownloadSetting
          open={openAnnoucement}
          handleClose={this.handleAnnoucementClose}
        />
      </React.Fragment>
    );
  }

  loadQueryString(props) {
    var queryParams = {
      foundries: this.getQueryVariable("foundry")
        .split(",")
        .filter(Boolean)
        .map(Number),
      technologies: this.getQueryVariable("technology")
        .split(",")
        .filter(Boolean)
        .map(Number),
      processes: this.getQueryVariable("process")
        .split(",")
        .filter(Boolean)
        .map(Number),
      groups: this.getQueryVariable("groups")
        .split(",")
        .filter(Boolean)
        .map(Number),
      families: this.getQueryVariable("family")
        .split(",")
        .filter(Boolean)
        .map(Number),
    };
    return (
      <MainProductPage
        inCartProducts={this.state.inCartProducts}
        onSelectClickHandler={this.onSelectClickHandler}
        queryParams={queryParams}
        {...props}
      />
    );
  }

  licensedProductHandler = (isEntitled) => {
    this.setState({ isEntitled });
  };

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return "";
  }

  handleCloseSnackbar = () => {
    const snackbarOpen = false;
    this.setState({ snackbarOpen });
  };

  downloadPartSuccessHandler = async () => {
    let { downloadingProducts, downloadingProductIndex } = this.state;

    for (
      ;
      downloadingProductIndex < downloadingProducts.length;
      downloadingProductIndex++
    ) {
      const downloadingProduct = downloadingProducts[downloadingProductIndex];
      await this.setState({
        downloadingProduct,
        downloadingProductIndex,
      });
      await this.downloadProduct();
      await this.setState({
        downloadingProduct,
        downloadingProductIndex,
      });
    }
    await this.setState({
      downloadingProductIndex,
    });
  };

  saveCartHandler = async (notificate = true) => {
    const { inCartProducts } = this.state;
    const productCodes = inCartProducts.map((p) => {
      const { product_code, baseproduct_id, product_name } = p;
      return JSON.stringify({
        product_code,
        baseproduct_id,
        product_name,
      });
    });

    try {
      await saveCart(productCodes);
      this.setState({
        snackbarVariant: SNACKBAR_VARIANT.SUCCESS,
        snackbarMsg: SAVECART_SUCCESS_MSG,
        snackbarOpen: notificate,
      });
    } catch (error) {
      if (error instanceof ResponseError) {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: SAVECART_ERROR_MSG,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: CONNECTION_ERROR_MSG,
          snackbarOpen: true,
        });
      }
    }
  };

  downloadAllHandler = async (downloadMode) => {
    this.setState({ downloadMode });
    let { inCartProducts, downloadingProducts } = this.state;
    inCartProducts = inCartProducts.filter((i) => !i.is_expired);
    this.state.inCartProducts = inCartProducts;
    await this.saveCartHandler(false);
    
    if (inCartProducts.length === 0) {
      return;
    }
    downloadingProducts = [...downloadingProducts, ...inCartProducts];
    inCartProducts = [];
    storeIncartProducts(inCartProducts);

    await this.setState({
      inCartProducts,
      downloadingProducts,
    });

    this.downloadPartSuccessHandler();
  };

  async downloadProduct() {
    const { downloadingProduct } = this.state;
    let { controller, cancelDownload } = this.state;
    this.setState({
      controller: browser_check_for_abort() ? new AbortController() : false,
      cancelDownload: false,
    });

    this.createZip();

    updateProductStatus(
      downloadingProduct["product_code"],
      downloadingProduct["status"],
      DOWNLOAD_STATUS.DOWNLOADING
    );
    downloadingProduct["status"] = DOWNLOAD_STATUS.DOWNLOADING;

    var downloadingParts = downloadingProduct["parts"];
    if (downloadingParts === undefined || downloadingParts.length === 0) {
      downloadingParts = await this.getProductParts(downloadingProduct);
      downloadingProduct["parts"] = downloadingParts;
    }

    const zipNumber = await this.downloadParts(
      downloadingParts,
      downloadingProduct
    );
    downloadingProduct["progress"] = 100;

    var new_status = downloadingProduct["status"];
    if (new_status !== DOWNLOAD_STATUS.ERROR) {
      new_status = DOWNLOAD_STATUS.SUCCESS;
    }
    updateProductStatus(
      downloadingProduct["product_code"],
      DOWNLOAD_STATUS.DOWNLOADING,
      new_status
    );
    downloadingProduct["status"] = new_status;
    this.setState({ downloadingProduct });
    const { fileName } = getZipFileName(downloadingProduct, zipNumber);

    const additionalFiles = {};
    const downloadLogName =
      zipNumber === 0
        ? format(DOWNLOAD_LOG_NAME)
        : format(DOWNLOAD_LOG_NAME, zipNumber);
    const downloadReport = generateTextDownloadReport(
      downloadingProduct,
      zipNumber === 0 ? 0 : zipNumber - 1
    );
    additionalFiles[downloadLogName] = downloadReport;
    const summaryReport = generateTextDownloadReport(downloadingProduct);
    additionalFiles[SUMMARY_LOG_NAME] = summaryReport;

    await this.generateZip(fileName, additionalFiles);
  }

  async downloadParts(downloadingParts, downloadingProduct) {
    const { downloadMode, controller } = this.state;
    let zipSize = 0;
    let zipNumber = downloadingProduct["zipNumber"]
      ? downloadingProduct["zipNumber"]
      : 0;
    for (
      var downloadingPartIndex = 0;
      downloadingPartIndex < downloadingParts.length;
      downloadingPartIndex++
    ) {
      let { cancelDownload } = this.state;
      if (cancelDownload) {
        this.setState({
          cancelDownload: false,
        });
        break;
      }
      const downloadingPart = downloadingParts[downloadingPartIndex];
      const progress =
        ((downloadingPartIndex + 1) / downloadingParts.length) * 100 - 1;
      downloadingProduct["progress"] = progress;
      await this.setState({
        downloadingProduct,
        downloadingPart,
        downloadingPartIndex,
      });

      let partData = null;
      let partSize = 0;
      let partStatus = null;
      let fileName = "";
      let triedNumber = DOWNLOAD_TRY_NUMBER;
      /**
       * try to download few times before give up
       */
      while (triedNumber >= 0 && partStatus !== DOWNLOAD_STATUS.SUCCESS) {
        const result = await this.downloadPart();
        triedNumber--;
        fileName = result.fileName;
        partData = result.partData;
        partSize = result.partSize;
        partStatus = result.partStatus;
      }

      if (partStatus === DOWNLOAD_STATUS.ERROR) {
        downloadingProduct["status"] = partStatus;
      }

      if (
        downloadMode === DOWNLOAD_ITEMS.PRODUCT_LEVEL &&
        partStatus === DOWNLOAD_STATUS.SUCCESS
      ) {
        zipSize += partSize;
        if (zipSize > MAX_ZIP_SIZE) {
          const { fileName } = getZipFileName(
            downloadingProduct,
            zipNumber + 1
          );
          const additionalFiles = {};
          const downloadLogName = format(DOWNLOAD_LOG_NAME, zipNumber + 1);
          const downloadReport = generateTextDownloadReport(
            downloadingProduct,
            zipNumber
          );
          additionalFiles[downloadLogName] = downloadReport;
          await this.generateZip(fileName, additionalFiles);
          this.createZip();
          zipSize = partSize;
          zipNumber++;
        }
        downloadingPart["group"] = zipNumber;
        this.state.zip.file(fileName, partData);
      }
    }
    return zipNumber === 0 ? 0 : ++zipNumber;
  }

  async getProductParts(product) {
    const code = product.product_code;
    try {
      const response = await getParts(code);
      const parts = response.data.records;
      return parts;
    } catch (error) {
      product.status = DOWNLOAD_STATUS.ERROR;
      product.percentage = 100;
      if (error instanceof ResponseError) {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: RESPONSE_ERROR_MSG,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: CONNECTION_ERROR_MSG,
          snackbarOpen: true,
        });
      }
      // let download page tries to fetch part list
      return [];
    }
  }

  downloadPart = async () => {
    let { downloadingPart, downloadMode, controller } = this.state;
    const fileName = getFileName(downloadingPart);
    let partData = null;
    let partStatus = null;
    let partSize = 0;

    if (downloadingPart.status === DOWNLOAD_STATUS.SUCCESS) {
      return { fileName, partData, partSize, partStatus };
    }

    try {
      const response = await signedUrl(downloadingPart);
      Object.assign(downloadingPart, new Blob([response.data]));
      const { url, signatures, part_size } = response.data;

      partSize = part_size;
      downloadingPart.status = DOWNLOAD_STATUS.SUCCESS;
      downloadingPart["signatures"] = signatures;
      downloadingPart["part_size"] = part_size;
      const curDate1 = getCurrentDate();
      partData = await this.fetchPart(url, part_size, controller);
      const curDate2 = getCurrentDate();
      const duration = curDate2.getTime() - curDate1.getTime();
      downloadingPart["download_percent"] = 100;
      downloadingPart["completed_time"] = getAthenaDateTimeFormat(curDate2);
      downloadingPart["duration"] = msToTime(duration);
      downloadingPart["speed"] = downloadSpeed(part_size, duration);
      downloadingPart["status"] = DOWNLOAD_STATUS.SUCCESS;

      // download success then save as a local file if download at part level
      if (downloadMode === DOWNLOAD_ITEMS.PART_LEVEL) {
        const _OBJECT_URL = window.URL.createObjectURL(new Blob([partData]));
        const link = document.createElement("a");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.setAttribute("href", _OBJECT_URL);
        link.setAttribute("download", fileName);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(_OBJECT_URL);
      }
    } catch (error) {
      downloadingPart.status = DOWNLOAD_STATUS.ERROR;
      downloadingPart.percentage = 100;
      if (error instanceof DesignStartError) {
        const message = error.state.message;
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: message,
          snackbarOpen: true,
        });
      } else if (error instanceof DOMException) {
        let snackBarMsg;
        if (error.name === "AbortError") {
          snackBarMsg = CANCEL_DOWNLOADS_MSG;
        } else {
          snackBarMsg = error.name;
        }
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.WARNING,
          snackbarMsg: snackBarMsg,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: error,
          snackbarOpen: true,
        });
      }
    }

    updateHistory(downloadingPart);

    this.setState({ downloadingPart });
    partStatus = downloadingPart["status"];
    return { fileName, partData, partSize, partStatus };
  };

  progressHandler = (loaded, total) => {
    const { downloadingPart } = this.state;
    const downloadingPercentage = Math.floor((loaded / total) * 100);
    downloadingPart["percentage"] = downloadingPercentage;
    this.setState({ downloadingPart });
  };

  removeFromCartHandler = (product) => {
    const inCart = [...this.state.inCartProducts];
    const inCartProducts = inCart.filter(
      (i) => i.product_code !== product.product_code
    );
    storeIncartProducts(inCartProducts);
    this.setState({
      inCartProducts,
    });
  };

  removeAllFromCartHandler = () => {
    storeIncartProducts([]);
    this.setState({
      inCartProducts: [],
    });
  };

  retryDownloadErrorProductHandler = async (downloadingProduct) => {
    await this.setState({ downloadingProduct });
    await this.downloadProduct();
  };

  cancelDownloadHandler = async (cancel) => {
    let { controller } = this.state;
    if (browser_check_for_abort()) {
      controller.abort();
      await this.setState({ cancelDownload: cancel });
    }
  };

  onSelectClickHandler = async (product) => {
    let inCartProducts = [...this.state.inCartProducts];
    if (product.is_group) {
      inCartProducts = await this._add_or_remove_group_products(inCartProducts, product);
    } else {
      inCartProducts = this._add_or_remove_product(inCartProducts, product);
    }
    storeIncartProducts(inCartProducts);
    this.setState({
      inCartProducts,
    });
  };

  insertAdditionalFiles(zipFile, productCode, additionalFiles) {
    if (!zipFile) return;

    this.addReadmeFile(zipFile);

    if (additionalFiles !== undefined) {
      for (const [key, value] of Object.entries(additionalFiles)) {
        zipFile.file(key, value);
      }
    }
  }

  addReadmeFile(zipFile) {
    if (zipFile) zipFile.file(README_NAME, README_TEXT);
  }

  createZip() {
    const { downloadMode } = this.state;
    if (downloadMode === DOWNLOAD_ITEMS.PRODUCT_LEVEL) {
      this.state.zip = new JSZip();
    }
    return this.state.zip;
  }

  async generateZip(productFileName, additionalFiles = {}) {
    const { downloadMode, downloadingProduct } = this.state;
    const { product_code } = downloadingProduct;
    const zip = this.state.zip;

    if (downloadMode === DOWNLOAD_ITEMS.PRODUCT_LEVEL) {
      this.insertAdditionalFiles(zip, product_code, additionalFiles);
      const zipfile = await zip.generateAsync({
        type: "blob",
        compression: "STORE",
        streamFiles: false,
      });
      this.state.zip = null;
      const _OBJECT_URL = window.URL.createObjectURL(new Blob([zipfile]));
      const link = document.createElement("a");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.setAttribute("href", _OBJECT_URL);
      link.setAttribute("download", productFileName);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(_OBJECT_URL);
    }
  }

  async fetchPart(url, partSize, controller) {
    let _data = {};
    await download_part(url, partSize, this.progressHandler, _data, controller);
    return _data.value;
  }

  _add_or_remove_product(products_inCart, product) {
    let selected = products_inCart.filter(
      (i) => i.product_code !== product.product_code
    );
    if (selected.length === products_inCart.length) {
      selected = [...products_inCart, product];
    }
    return selected.filter((i) => !i.is_expired);
    return selected;
  }

  async _add_or_remove_group_products(products_inCart, product) {
    const { isEntitled } = this.state;
    const selectedFoundries = [product.foundry_id];
    const selectedTechnologies = [];
    const selectedProcesses = [product.process_id];
    const selectedGroups = [product.product_group_id];
    const searchMode = false;
    const selectedFamilies = [product.family_id];
    try {
      const result = await getProducts(
        selectedFoundries,
        selectedTechnologies,
        selectedProcesses,
        selectedGroups,
        selectedFamilies,
        searchMode,
        isEntitled
      );
      const { products } = result;

      let inCart = products_inCart.filter((p) => {
        return (
          p.foundry_id !== product.foundry_id ||
          p.process_id !== product.process_id ||
          p.family_id !== product.family_id
        );
      });

      if(inCart.length === products_inCart.length){
        inCart = [...inCart, ...products];
      }
      return inCart.filter((i) => !i.is_expired);
    } catch (error) {
      if (error instanceof ResponseError) {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: error.messages,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: CONNECTION_ERROR_MSG,
          snackbarOpen: true,
        });
      }
    }
  }

  pluginDownloadHandler = async (saveTo, extractTo, sslCheck) => {
    let { inCartProducts } = this.state;
    if (inCartProducts.length === 0) {
      return;
    }

    var productCodes = "";
    for (var i = 0; i < inCartProducts.length; i++) {
      productCodes += format("{},", inCartProducts[i].product_code);
    }
    if (productCodes.length > 0) {
      productCodes = productCodes.substring(0, productCodes.length - 1);
    }
    const urlHandler = format(
      "{}products={}&outputdir={}&untardir={}&nosslcheck={}",
      PLUGIN_HANDLER,
      productCodes,
      saveTo,
      extractTo,
      !sslCheck
    );
    const link = document.createElement("a");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.setAttribute("href", urlHandler);
    link.click();
    link.parentNode.removeChild(link);

    await this.saveCartHandler(false);

    inCartProducts = [];
    storeIncartProducts(inCartProducts);
    this.setState({
      inCartProducts,
    });
  };

  handleAnnoucementClose = () => {
    this.setState({ openAnnoucement: false });
  };
}

function PluginDownloadSetting(props) {
  const { open, handleClose } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Notification</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            If you were re-routed from Product Download Hub ( PDH), then you are
            trying to access products that you had entitlement through our old
            legacy download system (Connect). To access the products that was in
            Connect, please make sure the “Licensed Product” switch is turned
            on. Use the left filtering panel to filter to the right products you
            are after.
          </p>
          <p>
            For best download performance, using the Athena Download Manager is
            the recommended approach. You can download it here if you have not
            done so before:
            <a href="https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e">
              Athena | Powered by Box
            </a>
          </p>
          <p>
            For any support, please open a ticket:{" "}
            <a href="https://services.arm.com/support/s/contactsupport">
              https://services.arm.com/support/s/contactsupport
            </a>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default App;
