import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import authentication from "@kdpw/msal-b2c-react";
import axios from "axios";
import * as Constants from "./components/common/Constants";
import BrowserCheckDialog from "./components/common/browserCheckDialog";
import { healthCheck } from "./services/ProductServices";

const { detect } = require("detect-browser");
const browser = detect();

authentication.initialize({
  instance: Constants.B2C_INSTANCE,
  tenant: Constants.B2C_TENANT,
  signInPolicy: Constants.B2C_SIGNINPOLICY,
  applicationId: Constants.B2C_CLIENTID,
  scopes: Constants.B2C_SCOPES,
  cacheLocation: "localStorage",
  redirectUri: Constants.B2C_REDIRECT_URL,
  validateAuthority: false
});

if (browser.name === "chrome" || browser.name === "firefox") {
  authentication.run(async () => {
    await healthCheck();
    axios.defaults.headers.common = {
      Authorization: `bearer ${authentication.getAccessToken()}`
    };
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById("root")
    );
  });
} else {
  ReactDOM.render(<BrowserCheckDialog />, document.getElementById("root"));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
