import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PlaylistAddCheck from "@material-ui/icons/PlaylistAddCheck";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import LogoutDialog from "../common/logoutDialog";

import Login from "../common/login";
import { isInternalAccount } from "../util/commonUtil";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class HeaderBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    internal: false,
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  componentDidMount() {
    const internal = isInternalAccount();
    this.setState({ internal });
  }
  render() {
    const { selected } = this.props;
    const { internal } = this.state;

    return (
      <div>
        <div className="u-no-print" role="banner">
          <div className="c-notifications-wrapper">
            <div className="arm-global-menu-wrapper" style={{ height: "55px" }}>
              <div className="arm-global-menu">
                <nav
                  className="top-bar js-mobile-navigation"
                  data-topbar=""
                  role="navigation"
                >
                  <div className="global-menu">
                    <ul className="title-area">
                      <span className="navigation-dropdown-label">
                        <a href="https://developer.arm.com">
                          <span>
                            <img
                              src="https://developer.arm.com/shared/developer.arm.com/img/arm-developer.svg"
                              alt="ARM Developer"
                            />
                          </span>
                        </a>
                      </span>

                      <li className="menu">
                        <ul>
                          <li>
                            <Link
                              to="/products"
                              className="underline"
                              href="/products"
                              title="Products"
                            >
                              Products
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="underline"
                              to="/support"
                              title="Support"
                            >
                              Support
                            </Link>
                          </li>
                          {internal && (
                            <li>
                              <Link
                                className="underline"
                                to="/download/history"
                                title="Report"
                              >
                                Report
                              </Link>
                            </li>
                          )}
                          {internal && (
                            <li>
                              <Link
                                className="underline"
                                to="/errata"
                                title="Errata"
                              >
                                Errata
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>

                      <li className="search js-search" />

                      <li
                        className="menu-icon c-mobile-toggle c-mobile-search-toggle js-search-homepage"
                        data-toggle="search"
                      >
                        <Link
                          to="#"
                          tabIndex="0"
                          title="Search"
                          aria-label="Search"
                          aria-haspopup="true"
                        >
                          <i className="fa fa-search" />
                          <span className="sr-only">Search</span>
                        </Link>
                      </li>

                      <li
                        className="menu-icon c-mobile-toggle c-mobile-navigation-toggle js-mobile-toggle"
                        data-toggle="navigation"
                      >
                        <Link
                          to="#"
                          tabIndex="0"
                          title="Mobile Navigation"
                          aria-label="Mobile Navigation"
                          aria-haspopup="true"
                        >
                          <i className="fa fa-bars" />
                          <span className="sr-only">Mobile Navigation</span>
                        </Link>
                      </li>

                      <li className="developer-user-menu">
                        <div
                          id="c-67c32bd9-a5ad-49c6-bb81-86de6d1ed98e"
                          className="c-user-menu"
                          role="navigation"
                          aria-label="User menu"
                        >
                          <ul
                            className="c-user-menu__items c-navigation__items c-user-menu__root"
                            role="menubar"
                          >
                            <li
                              className="c-user-menu__item"
                              aria-haspopup="true"
                              role="menuitem"
                            >
                              <span className="show-for-large-up">
                                <Login />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </li>

                      <li className="developer-user-menu">
                        <div
                          id="c-67c32bd9-a5ad-49c6-bb81-86de6d1ed98e"
                          className="c-user-menu"
                          role="navigation"
                          aria-label="User menu"
                        >
                          <ul
                            className="c-user-menu__items c-navigation__items c-user-menu__root"
                            role="menubar"
                          >
                            <li
                              className="c-user-menu__item"
                              aria-haspopup="true"
                              role="menuitem"
                            >
                              <IconButton
                                color="inherit"
                                style={{ top: "0px" }}
                              >
                                <AccountCircle />
                              </IconButton>
                              <ul
                                className="c-user-menu__items c-navigation__items c-user-menu__section is-aligned-right"
                                aria-hidden="true"
                                role="menu"
                              >
                                <li
                                  className="c-user-menu__item"
                                  aria-haspopup="true"
                                  role="menuitem"
                                >
                                  <LogoutDialog />
                                </li>
                              </ul>
                            </li>
                            <li
                              className="c-user-menu__item"
                              aria-haspopup="true"
                              role="menuitem"
                              title="Show Cart"
                            >
                              <Link to="/cart">
                                <IconButton
                                  color="inherit"
                                  style={{ top: "3px" }}
                                >
                                  <Badge
                                    badgeContent={selected.length}
                                    color="secondary"
                                  >
                                    <ShoppingCart />
                                  </Badge>
                                </IconButton>
                              </Link>
                            </li>
                            <li
                              className="c-user-menu__item"
                              aria-haspopup="true"
                              role="menuitem"
                              title="Download Status"
                            >
                              <Link to="/Download">
                                <IconButton
                                  color="inherit"
                                  style={{ top: "3px" }}
                                >
                                  <PlaylistAddCheck />
                                </IconButton>
                              </Link>
                            </li>
                            <li
                              className="c-user-menu__item"
                              aria-haspopup="true"
                              role="menuitem"
                              title="Faqs"
                            >
                              <Link to="/FAQ">
                                <IconButton
                                  color="inherit"
                                  style={{ top: "3px" }}
                                >
                                  <QuestionAnswer />
                                </IconButton>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                    <hr />
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderBar);
