import React from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Info from "@material-ui/icons/Info";

export default function BrowserCheckDialog() {
    const [open] = React.useState(true);

    function handleClose() {
        window.close()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                disableBackdropClick={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ color: 'black' }}
            >
                <DialogContent align='center'>
                    <Info align="center" style={{ fontSize: '80px' }} />
                </DialogContent>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ fontSize: '25px', fontWeight: 'bold', color: 'black' }} align="center">
                        Browser not supported
              </DialogContentText>
                    <br />
                    <DialogContentText id="alert-dialog-description" style={{ fontSize: '20px', color: 'black' }} align="center">
                        Designstart-beta currently supports Google Chrome or Mozilla Firefox only.
              </DialogContentText>
                </DialogContent>
            </Dialog>
        </div >
    );
}