import * as Constants from "../components/common/Constants";
import * as CommonUtil from "../components/util/commonUtil";
import format from "string-format";
import ConnectionError from "../error/ConnectionError";

export const getSapCustomer = async () => {
  const config = {};

  try {
    const response = await CommonUtil.myAxiosGet(
      Constants.REPORT_SERVVER + Constants.GET_SAPCUSTOMER,
      config
    );
    return response.data;
  } catch (err) {
    throw new ConnectionError();
  }
};

export const getAllFoundries = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_FOUNDRY_REPORT
  );
};

export const getDownloadedFoundries = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_DOWNLOADED_FOUNDRY
  );
};

export const getDownloadedTechnologies = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_DOWNLOADED_TECHNOLOGY
  );
};

export const getAllTechnologies = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_TECHNOLOGY_REPORT
  );
};

export const getAllProccesses = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_PROCESS_REPORT
  );
};

export const getDownloadedProccesses = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_DOWNLOADED_PROCESS
  );
};

export const getAllGroups = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_GROUP_REPORT
  );
};

export const getDownloadedGroups = async (idoc, userid) => {
  return filterBaseProductRelationship(
    idoc,
    userid,
    Constants.GET_DOWNLOADED_GROUP
  );
};

const filterBaseProductRelationship = async (idoc, userid, api) => {
  if (idoc == "") {
    idoc = null;
  }
  const config = {
    params: {
      sapcustomer: idoc,
      userid: userid,
    },
  };

  try {
    const response = await CommonUtil.myAxiosGet(
      Constants.REPORT_SERVVER + api,
      config
    );
    return response.data;
  } catch (err) {
    throw new ConnectionError();
  }
};

export const getUsernameBySapcustomer = async (idoc) => {
  const config = {};

  try {
    const response = await CommonUtil.myAxiosGet(
      Constants.REPORT_SERVVER + format(Constants.GET_USENAME_BY_IDOC, idoc),
      config
    );
    return response.data;
  } catch (err) {
    throw new ConnectionError();
  }
};

export const getDownloadHistoryReport = async (
  selectedSapCustomer,
  selectedUsername,
  selectedFoundry,
  selectedTechnology,
  selectedProcess,
  selectedGroup,
  rowsPerPage,
  page,
  startedDate,
  endedDate,
  historyLevel,
  packageType,
  productcode
) => {
  const config = {
    params: {
      sapcustomer: selectedSapCustomer,
      userid: selectedUsername,
      foundry: selectedFoundry,
      technology: selectedTechnology,
      process: selectedProcess,
      group: selectedGroup,
      ipp: rowsPerPage,
      page: page,
      datestarted: startedDate,
      dateended: endedDate,
      packageType: packageType,
      productcode: productcode,
    },
  };
  var endPoint;
  if (historyLevel == Constants.PART_LEVEL) {
    endPoint = Constants.GET_DOWNLOAD_HISTORY_REPORT;
  } else {
    endPoint = Constants.GET_DOWNLOAD_HISTORY_PRODUCT_REPORT;
  }
  const response = await CommonUtil.myAxiosGet(
    Constants.REPORT_SERVVER + endPoint,
    config,
    false,
    Constants.REPORT_TIMEOUT
  );
  return response.data;
};
