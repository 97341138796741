import React, { Component } from "react";

import NavigationPanel from "../components/common/navigationPanel";
import AccordionItem from "../components/common/accordionItem";
import {FAQ_QUESTIONS} from "../components/common/faqConstants";

class FAQPage extends Component {
  constructor() {
    super();
    this.state = {
      breadcrumbItems: [
        { link: "/", text: "Home" },
        { link: "/faq", text: "FAQ" }
      ],
      questions: FAQ_QUESTIONS,
    };
    this.renderQuestion = this.renderQuestion.bind(this);
  }
  renderQuestion(key) {
    return <AccordionItem key={key} index={key} details={this.state.questions[key]} />
  }
  render() {
    const { breadcrumbItems } = this.state;
    return (
      <React.Fragment>
        <NavigationPanel title="FAQ" items={breadcrumbItems} />
        <div className="accordion-container">{Object.keys(this.state.questions).map(this.renderQuestion)} </div>
      </React.Fragment>
    );
  }
}

export default FAQPage;