import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const NavigationPanel = ({ title, items, openAnnoucement }) => {
  const count = items.length - 1;
  var showAnnoucement = "none";
  if(!openAnnoucement){
    showAnnoucement = "block";
  }
  return (
    <div
      className="nagivation-header"
      style={{ padding: "0px", marginBottom: "20px" }}
    >
      <div className="large-12" style={{ padding: "5px 60px" }}>
        <ul className="breadcrumbs">
          {items.slice(0, count).map((item) => (
            <li key={item.link}>
              <Link to={item.link}>
                <b>{item.text}</b>
              </Link>
            </li>
          ))}
          <li>
            <span>{items[count].text}</span>
          </li>
        </ul>
        <h1>{title}</h1>
        <div style={{ display: showAnnoucement}}>
          <p style={{ color: "white", fontSize: "14px" }}>
            If you were re-routed from Product Download Hub ( PDH), then you are
            trying to access products that you had entitlement through our old
            legacy download system (Connect). To access the products that was in
            Connect, please make sure the “Licensed Product” switch is turned
            on. Use the left filtering panel to filter to the right products you
            are after.
          </p>
          <p style={{ color: "white", fontSize: "14px" }}>
            For best download performance, using the Athena Download Manager is
            the recommended approach. You can download it here if you have not
            done so before:{" "}
            <a
              href="https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e"
              style={{ color: "blue", fontSize: "14px" }}
            >
              Athena | Power by Box
            </a>
          </p>
          <p style={{ color: "white", fontSize: "14px" }}>
            For any support, please open a ticket:{" "}
            <a
              href="https://services.arm.com/support/s/contactsupport"
              style={{ color: "blue", fontSize: "14px" }}
            >
              https://services.arm.com/support/s/contactsupport
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NavigationPanel;
