import React from "react";

class Login extends React.Component {
  render() {
    const localMsalApp = window.msal;
    const user =
      localMsalApp.getUser() === null
        ? undefined
        : localMsalApp.getUser().idToken.emails;
    return <div>{user === undefined ? "Guest" : user}</div>;
  }
}

export default Login;
