// You can type html tags in answers, all the HTML code gets rendered as it is on the web page

export const FAQ_QUESTIONS = {
    question1: {
        summary: 'Where’s my downloaded product stored?',
        answer: 'It will be stored in your browser download location path. By default, it will be your Downloads directory. Otherwise, it will be in your customized download location for users that have changed the download path.'
    },
    question2: {
        summary: 'What if I want to store my products in a different path than Downloads?',
        answer: 'By default, your browser will store all downloaded files inside Downloads directory. But it can be customized by changing the settings. Go to your browser settings and change the path for your download location under the Downloads section.'
    },
    question3: {
        summary: 'What’s name of my downloaded product?',
        answer: 'Your product will be a package of all part .tgz files zip into a single .zip file with the SAP code as the name, i.e TS66LB000.zip, in your download location.'
    },
    question4: {
        summary: 'Why do I see multiple zip files for the same product in my download directory?',
        answer: 'For large size product download, it will be split into multiple manageable zip files if the size exceeds a certain limit. This is for download optimization purpose and to avoid issue with packaging a large file.'
    },
    question5: {
        summary: 'How do I extract my products?',
        answer: 'Please download the extractor utility for your OS platform. For Linux - <a target="_blank" rel="noopener noreferrer" href="https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e/file/949836961218">Click here to download</a>, For Windows - <a target="_blank" rel="noopener noreferrer" href="https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e/file/950850256532">Click here to download</a>. Run --help for usage message '
    },
    question6: {
        summary: 'Can I save my cart for future usage?',
        answer: 'Cart can be saved by clicking on the [Save Cart] button located on the left panel of the Cart view.'
    },
    question7: {
        summary: 'What are the supported browsers?',
        answer: 'Google Chrome and Mozilla Firefox.'
    }
};