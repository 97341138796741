import React from "react";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import Slide from "@material-ui/core/Slide";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

function SnackbarContentWrapper(props) {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span>
          <Icon style={{ paddingRight: "10px" }} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

function CustomSnackbar(props) {
  const { variant = "success", message, open, handleClose } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      TransitionComponent={TransitionUp}
      open={open}
      // autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContentWrapper variant={variant} message={message} />
    </Snackbar>
  );
}

export default CustomSnackbar;
