import React, { Component } from "react";
import { Link } from "react-router-dom";

import format from "string-format";

import Checkbox from "@material-ui/core/Checkbox";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Assignment from "@material-ui/icons/Assignment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import isEnableDisplay from "./common/ulti";
import Table from "./common/table";
import {
  getPackageType,
  getErrataNoticesByProduct,
  downloadErrataNotice,
} from "../services/ProductServices";

import { SNACKBAR_VARIANT } from "../components/common/Constants";
import CustomSnackbar from "../components/common/CustomSnackbar";

class ErrataUpdatesDialog extends Component {
  state = {
    progressLoading: false,
  };
  render() {
    const { progressLoading } = this.state;
    const { open, handleClose, loading, notices } = this.props;
    return (
      <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <DialogContent style={{ minHeight: "300px", minWidth: "500px" }}>
          {(loading || progressLoading) && <LinearProgress />}
          {this.displayNoticeTypeSessions(notices, loading, progressLoading)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  displayNoticeTypeSessions = (notices, loading, progressLoading) => {
    if (loading || progressLoading) {
      return (
        <div>
          <h6>fetching ...</h6>
        </div>
      );
    }
    if (Object.keys(notices).length === 0) {
      return (
        <div>
          <h6>There is no notice for the product</h6>
        </div>
      );
    } else {
      return Object.keys(notices).map((key) => {
        const noticeType = key.split("-");
        return (
          <div key={key}>
            <h6>{noticeType[0]}</h6>
            <ul>{this.displayNotices(notices[key])}</ul>
          </div>
        );
      });
    }
  };
  displayNotices = (notices) => {
    return Object.keys(notices).map((version) => {
      const docName = notices[version]["name"];
      const docKey = notices[version]["key"];
      const docVersion = notices[version]["version"];
      return (
        <li key={docKey}>
          <a target="_blank" onClick={() => this.downloadErrataNotice(docKey)}>
            {docName} v.{docVersion}
          </a>
        </li>
      );
    });
  };

  downloadErrataNotice = async (key) => {
    this.setState({ progressLoading: true });
    const responseData = await downloadErrataNotice(key);
    this.setState({ progressLoading: false });
    const link = document.createElement("a");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.setAttribute("href", responseData["url"]);
    link.setAttribute("download", responseData["name"]);
    link.setAttribute("target", "_blank");
    link.click();
    link.parentNode.removeChild(link);
  };
}

class ProductTable extends Component {
  META_COLUMNS = {
    key: "product",
    key_row: "product_code",
    columns: [
      {
        key: "product_group",
        columnStyleWidth: "5%",
        headComponent: () =>
          this.props.isSubpage
            ? this.renderCheckBox()
            : this.renderShoppingCart(),
        content: (product) => {
          const _isSelected = this.isSelected(product);
          const _isDisable = product.is_expired;
          return (
            <Checkbox
              id={product.product_code}
              name={product.product_code}
              value={product.product_code}
              checked={_isSelected > 0}
              disabled={_isDisable}
              onChange={() => this.props.onSelectClickHandler(product)}
              indeterminate={
                _isSelected < product.total_members && _isSelected > 0
              }
              color="primary"
            />
          );
        },
      },
      {
        path: "product_name",
        label: "Product Description",
        columnStyleWidth: "35%",
        content: (product) => this.renderNameColumn(product),
      },
      {
        path: "packageType",
        label: "Entitlement",
        columnStyleWidth: "10%",
        content: (product) => this.renderPackageTypeCol(product),
      },
      {
        path: "product_code",
        label: "Product Code",
        columnStyleWidth: "15%",
      },
      {
        path: "technology_name",
        label: "Technology",
        columnStyleWidth: "10%",
      },
      {
        path: "product_group_name",
        label: "Product Groups",
        columnStyleWidth: "15%",
      },
      {
        label: "Latest Version",
        key_col: "revision",
        columnStyleWidth: "20%",
        content: (product) => {
          return format("{0}-{1}", product.revision, product.version);
        },
      },
      {
        path: "size",
        label: "Size(MB)",
        columnStyleWidth: "10%",
        content: (product) => {
          var sizeMB = product.size / (1000 * 1000);
          sizeMB = sizeMB.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          });
          return format("{0}", sizeMB);
        },
      },
      {
        key: "readme",
        label: "Readme",
        columnStyleWidth: "10%",
        content: (product) => this.renderReadMeCol(product),
      },
      {
        path: "ended_date",
        label: "Expired date",
        columnStyleWidth: "10%",
      },
    ],
  };

  state = {
    open: false,
    errataDialogOpen: false,
    errataLoading: false,
    errateNotices: {},
    snackbarOpen: false,
    snackbarMsg: "",
    snackbarVariant: SNACKBAR_VARIANT.ERROR,
  };

  renderCheckBox = () => {
    const product = this.props.productGroup;
    const _isSelected = this.isSelected(product);
    return (
      <Checkbox
        value={product.product_code}
        checked={_isSelected === product.total_members}
        onChange={() => this.props.onSelectClickHandler(product)}
        indeterminate={_isSelected > 0 && _isSelected < product.total_members}
        color="primary"
      />
    );
  };

  renderShoppingCart() {
    return <ShoppingCart style={{ marginLeft: "13px" }} />;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderReadMeCol = (product) => {
    return product.is_group === true ? (
      "-"
    ) : (
      <Assignment
        onClick={(e) => this.downloadReportHandler(e, product)}
        value={product.product_code}
      />
    );
  };

  downloadReportHandler = (e, product) => {
    e.stopPropagation();
    const { openProductReport } = this.props;
    openProductReport(product);
  };

  renderPackageTypeCol = (product) => {
    return product.packageType != null ? (
      product.packageType
    ) : (
      <Link to={`#`} onClick={(e) => this.getPackageType(e, product)}>
        Show
      </Link>
    );
  };

  getPackageType = async (e, product) => {
    e.stopPropagation();
    product.packageType = await getPackageType(product);
    this.setState({ product });
  };

  renderNameColumn = (product) => {
    const hasNotices = this.props.hasNotices;
    return this.props.flgGrouping &&
      product.product_group_name === "Logic IP" ? (
      <Link
        to={`/products/logic_ip/${product.product_group_id}/${product.product_group_name}/${product.foundry_id}/${product.foundry_name}/${product.family_id}/${product.family_name}/${product.process_id}/${product.process_name}/${product.total_members}`}
      >
        {product.product_name}
      </Link>
    ) : (
      <div>
        <div>{product.product_name}</div>
        <p />
        {hasNotices.indexOf(product.product_code) > -1 && (
          <Link onClick={() => this.showErrataUpdates(product)}>Notices</Link>
        )}
      </div>
    );
  };

  isSelected = (product) => {
    const { inCartProducts } = this.props;
    const isSelected = inCartProducts.filter((ic) => {
      if (product.is_group === true) {
        return (
          product.foundry_id === ic.foundry_id &&
          product.process_id === ic.process_id &&
          product.family_id === ic.family_id
        );
      } else {
        return ic.product_code === product.product_code;
      }
    });
    return isSelected.length;
  };

  isExpired = (product) => {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return product.ended_date < date;
  }

  checkboxStatus = (selectedNum, totalNumber = 1) => {
    if (selectedNum === totalNumber) {
      return "indeterminate";
    } else if (selectedNum > 0) {
      return "checked";
    } else {
      return "";
    }
  };

  render() {
    const {
      errataDialogOpen,
      errateNotices,
      errataLoading,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
    } = this.state;
    const { products, handleChangePage, enableDisplay, loading } = this.props;
    return (
      <React.Fragment>
        <div
          className="display-enable"
          style={{ display: isEnableDisplay(enableDisplay) }}
        />
        <Table
          metadata={this.META_COLUMNS}
          data={products}
          loading={loading}
          handleChangePage={handleChangePage}
          table_id="productTable"
        />
        <ErrataUpdatesDialog
          open={errataDialogOpen}
          handleClose={this.handleErrataNoticeClose}
          loading={errataLoading}
          notices={errateNotices}
        />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={this.handleCloseSnackbar}
          variant={snackbarVariant}
        />
      </React.Fragment>
    );
  }

  showErrataUpdates = async (product) => {
    this.setState({
      errataDialogOpen: true,
      errataLoading: true,
      errateNotices: {},
    });

    try {
      const errateNotices = await getErrataNoticesByProduct(product);
      this.setState({
        errateNotices,
      });
    } catch (error) {
      const snackbarMsg = "Can't load notices, please retry!";
      this.setState({ snackbarMsg, snackbarOpen: true });
    }
    this.setState({
      errataLoading: false,
    });
  };

  handleErrataNoticeClose = () => {
    this.setState({ errataDialogOpen: false, errataLoading: false });
  };

  handleCloseSnackbar = () => {
    const snackbarOpen = false;
    this.setState({ snackbarOpen });
  };

}

export default ProductTable;
