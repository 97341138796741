import DesignStartError from "./DesignStartError";

import { RESPONSE_ERROR_MSG } from "../components/common/Constants";

class ResponseError extends DesignStartError {
  constructor() {
    super(RESPONSE_ERROR_MSG);
  }
}

export default ResponseError;
