import React, { Component } from "react";


import NavigationPanel from "../components/common/navigationPanel";
import ProductPage from "../components/productPage";

class MainProductPage extends Component {
  state = {
    flgGrouping: true,
    breadcrumbItems: [
      { link: "/", text: "Home" },
      { link: "/products", text: "Products" },
    ],
  };


  render() {
    const { flgGrouping, breadcrumbItems } = this.state;
    const {
      openAnnoucement,
      inCartProducts,
      onSelectClickHandler,
      queryParams,
      licensedProductHandler,
      isEntitled,
    } = this.props;
    return (
      <React.Fragment>
        <NavigationPanel title="Physical IP" items={breadcrumbItems} openAnnoucement={openAnnoucement}/>
        <ProductPage
          isSubpage={false}
          flgGrouping={flgGrouping}
          inCartProducts={inCartProducts}
          onSelectClickHandler={onSelectClickHandler}
          queryParams={queryParams}
          licensedProductHandler={licensedProductHandler}
          isEntitled={isEntitled}
        />
        
      </React.Fragment>
    );
  }
}


export default MainProductPage;
