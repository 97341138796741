import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";

class PluginSetting extends Component {
  state = {
    saveToValue: "",
    saveToError: false,
    extractToValue: "",
    extractToError: false,
    sslCheck: true,
  };
  render() {
    const { cancelHandler } = this.props;
    const {
      saveToValue,
      saveToError,
      extractToValue,
      extractToError,
      sslCheck,
    } = this.state;
    return (
      <React.Fragment>
        <DialogTitle
          id="alert-dialog-title"
          style={{ marginTop: "0px", paddingTop: "0px" }}
        >
          {"Download Setting"}
        </DialogTitle>
        <DialogContent>
          <div class="row">
            <FormControlLabel
              control={
                <Switch
                  checked={sslCheck}
                  onChange={this.sslCheckHandler}
                  name="sslcheck"
                  color="primary"
                />
              }
              label="Validate SSL"
            />
          </div>
          <div class="row">
            <div className="col-md-12">
              <TextField
                placeholder={
                  navigator.appVersion.indexOf("Win") != -1
                    ? "c:/download/myfolder"
                    : "/home/user/download"
                }
                id="saveTo"
                label="Save to"
                value={saveToValue}
                error={saveToError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  this.setState({ saveToValue: e.target.value });
                }}
                helperText={
                  saveToError
                    ? "Blank field or Entered backward slash (\\)"
                    : "Must be absolute path with (/)"
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div class="row">
            <div className="col-md-12">
              <TextField
                placeholder={
                  navigator.appVersion.indexOf("Win") != -1
                    ? "c:/download/arm"
                    : "/home/user/download"
                }
                id="extractTo"
                label="Extract to"
                value={extractToValue}
                error={extractToError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  this.setState({
                    extractToValue: e.target.value,
                  })
                }
                helperText={
                  extractToError
                    ? "Entered backward slash (\\)"
                    : "Must be absolute path with (/)"
                }
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button onClick={this.okHandlerHook} color="primary" autoFocus>
            Download
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  sslCheckHandler = (event) => {
    this.setState({ sslCheck: event.target.checked });
  };

  okHandlerHook = () => {
    const { okHandler } = this.props;
    const { saveToValue, extractToValue, sslCheck } = this.state;
    let saveToError = false,
      extractToError = false;
    if (
      saveToValue == null ||
      saveToValue.trim() == "" ||
      saveToValue.trim().indexOf("\\") != -1
    ) {
      saveToError = true;
    }

    if (
      extractToValue != null &&
      extractToValue.trim() !== "" &&
      extractToValue.trim().indexOf("\\") != -1
    ) {
      extractToError = true;
    }

    if (saveToError || extractToError) {
      this.setState({ extractToError, saveToError });
      return;
    }
    okHandler(saveToValue, extractToValue, sslCheck);
  };
}

class PluginQuesion extends Component {
  render() {
    const { cancelHandler, yesHandler } = this.props;
    return (
      <React.Fragment>
        <DialogTitle
          id="alert-dialog-title"
          style={{ marginTop: "0px", paddingTop: "0px" }}
        >
          {"Have you installed AthenaDM and registered Cert?"}
        </DialogTitle>
        <DialogContent>
          <div style={{ paddingBottom: "10px" }}>
            If the answers are <b>"Yes"</b> then click on <b>"Continue"</b> to
            begin downloading. Otherwise please follow the below steps to setup.
            Click <b>"Cancel"</b> to go back to use HTTPS download by clicking
            on <b>"Download By: HTTPS"</b> button.
          </div>
          <ol>
            <li>
              <div>
                Download AthenaDM. We support both Linux and Windows, click{" "}
                <a
                  href="https://app.box.com/s/71izrc16fcmeoleswb7u0nsg1rdh9p3e"
                  target="_blank"
                >
                  here
                </a>{" "}
                to download.
              </div>
            </li>
            <li>
              <div>
                Install AthenaDM. Please refer to{" "}
                <a
                  href="https://dsextractor-public.s3.eu-west-2.amazonaws.com/AthenaDM_Plugin_Download_Instructions.pdf"
                  target="_blank"
                >
                  the User Guide
                </a>
              </div>
            </li>
            <li>
              Register your Cert. Please refer to the User Guide. If you do not
              have the certificate yet, please send an email to{" "}
              <a href="mailto:athena-support@arm.com">athena-support@arm.com</a>
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler} color="primary">
            Cancel
          </Button>
          <Button onClick={yesHandler} color="primary">
            Continue
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

class FinalStep extends Component {
  render() {
    const { closeHandler } = this.props;
    return (
      <React.Fragment>
        <DialogTitle
          id="alert-dialog-title"
          style={{ marginTop: "0px", paddingTop: "0px" }}
        >
          {"Launch AthenaDM"}
        </DialogTitle>
        <DialogContent>
          <div class="row">
            <div className="col-md-12">
              Download will be launched in a separate Terminal window.
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color="primary">
            Close
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default class PluginDownloadSetting extends Component {
  state = {
    activeStep: 1,
  };
  render() {
    const { activeStep } = this.state;
    const { open } = this.props;
    return (
      <div class="row" className="col-md-12">
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={open}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>
            <Stepper
              activeStep={activeStep}
              style={{ margin: "0px", padding: "0px" }}
            >
              <Step key="1">
                <StepLabel>Checklist</StepLabel>
              </Step>
              <Step key="2">
                <StepLabel>Setting</StepLabel>
              </Step>
              <Step key="3">
                <StepLabel>Download</StepLabel>
              </Step>
            </Stepper>
          </DialogTitle>
          {this.getStepContent(activeStep)}
        </Dialog>
      </div>
    );
  }

  getStepContent = (activeStep) => {
    switch (activeStep) {
      case 1:
        return (
          <PluginQuesion
            cancelHandler={this.props.cancelHandler}
            yesHandler={this.yesHandler}
          />
        );
      case 2:
        return (
          <PluginSetting
            cancelHandler={this.cancelHandlerHook}
            okHandler={this.okHandlerHook}
          />
        );
      default:
        return <FinalStep closeHandler={this.cancelHandlerHook} />;
    }
  };

  yesHandler = () => {
    this.setState({ activeStep: 2 });
  };

  cancelHandlerHook = () => {
    this.setState({ activeStep: 1 });
    const { cancelHandler } = this.props;
    cancelHandler();
  };

  okHandlerHook = (saveToValue, extractToValue, sslCheck) => {
    this.setState({ activeStep: 3 });
    this.props.okHandler(saveToValue, extractToValue, sslCheck);
  };
}
