import React, { Component } from "react";

import NavigationPanel from "../components/common/navigationPanel";

class SupportPage extends Component {
  state = {
    breadcrumbItems: [
      { link: "/", text: "Home" },
      { link: "/support", text: "Support" }
    ]
  };

  render() {
    const { breadcrumbItems } = this.state;
    return (
      <React.Fragment>
        <NavigationPanel title="Physical IP" items={breadcrumbItems} />
        <div style={{ paddingLeft: "15px" }}>
          <a href="/FAQ">Frequently Asked Questions</a>
          <p style={{ fontSize: "16px", paddingTop: "8px" }}>To obtain support from Arm please email support-athena@arm.com</p>
        </div>
      </React.Fragment>
    );
  }
}

export default SupportPage;
