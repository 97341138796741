import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import DropBox from "./common/DropBox";
import * as Constants from "../components/common/Constants";

import {
  getSapCustomer,
  getUsernameBySapcustomer,
  getAllFoundries,
  getAllTechnologies,
  getAllProccesses,
  getAllGroups,
  getDownloadedFoundries,
  getDownloadedTechnologies,
  getDownloadedProccesses,
  getDownloadedGroups,
} from "../services/HistoryServices";

const today = new Date();
const todayDate = today.toISOString().substring(0, 10);

export default class HistoryFilter extends Component {
  state = {
    sapCustomerList: [],
    selectedSapCustmer: "",
    usernameList: [],
    selectedUserId: "",
    foundries: [],
    selectedFoundry: "",
    technologies: [],
    selectedTechnology: "",
    processes: [],
    selectedProcess: "",
    groups: [],
    selectedGroup: "",
    startedDate: todayDate,
    endedDate: todayDate,
    historyLevel: Constants.PRODUCT_LEVEL,
    packageTypes: [
      { code: "FB", value: "FB" },
      { code: "FE", value: "FE" },
    ],
    packageType: null,
    productcode: null,
  };

  render() {
    const { generateReportHandler, exportHandler, loading } = this.props;
    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.historyLevel == Constants.PRODUCT_LEVEL}
              onChange={this.handleHistoryLevel}
              name="historyLevel"
              color="primary"
            />
          }
          label="Product Level"
        />
        <TextField
          style={{ paddingTop: "15px", width: "100%" }}
          id="startedDate"
          label="Start Date"
          type="date"
          defaultValue={this.state.startedDate}
          onChange={this.startDateHandler}
        />
        <TextField
          style={{ paddingTop: "15px", width: "100%" }}
          id="endedDate"
          label="End Date"
          type="date"
          defaultValue={this.state.endedDate}
          onChange={this.endDateHandler}
        />
        <TextField
          style={{ paddingTop: "15px", width: "100%" }}
          id="productcode"
          label="Product Code"
          defaultValue={this.state.productcode}
          onChange={this.productCodeHandler}
        />
        <DropBox
          compId="packageType"
          label="Package Type"
          values={this.state.packageTypes}
          itemKey="code"
          itemText="value"
          selectedValue={this.state.packageType}
          onChangeHandler={this.packageTypeHandler}
        />
        <DropBox
          compId="sapCustomerFilter"
          label="Sap Customer"
          values={this.state.sapCustomerList}
          itemKey="idoc"
          itemText="fullName"
          selectedValue={this.state.selectedSapCustmer}
          onChangeHandler={this.sapCustomerFilter_onChange}
          loading={loading || this.state.loading}
        />
        <DropBox
          compId="userFilter"
          label="Username"
          values={this.state.usernameList}
          itemKey="id"
          itemText="username"
          selectedValue={this.state.selectedUserId}
          onChangeHandler={this.sapUsernameFilter_onChange}
          loading={loading || this.state.loading}
        />
        <DropBox
          compId="foundryFilter"
          label="Foundry"
          values={this.state.foundries}
          itemKey="id"
          itemText="name"
          selectedValue={this.state.selectedFoundry}
          onChangeHandler={this.foundryFilterHandler}
          loading={loading || this.state.loading}
        />
        <DropBox
          compId="technologyFilter"
          label="Technology"
          values={this.state.technologies}
          itemKey="id"
          itemText="name"
          selectedValue={this.state.selectedTechnology}
          onChangeHandler={this.technologyFilterHandler}
          loading={loading || this.state.loading}
        />
        <DropBox
          compId="proccessFilter"
          label="Proccess"
          values={this.state.processes}
          itemKey="id"
          itemText="name"
          selectedValue={this.state.selectedProcess}
          onChangeHandler={this.processFilterHandler}
          loading={loading || this.state.loading}
        />
        <DropBox
          compId="groupFilter"
          label="Groups"
          values={this.state.groups}
          itemKey="id"
          itemText="name"
          selectedValue={this.state.selectedGroup}
          onChangeHandler={this.familyFilterHandler}
          loading={loading || this.state.loading}
        />
        <div style={{ paddingTop: "10px" }}>
          <Button
            style={{ width: "100%" }}
            variant="outlined"
            color="primary"
            onClick={() => generateReportHandler(this.state)}
          >
            Report
          </Button>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <Button
            style={{ width: "100%" }}
            variant="outlined"
            color="primary"
            onClick={() => exportHandler(this.state)}
          >
            Export
          </Button>
        </div>
      </React.Fragment>
    );
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const sapCustomerList = await this.loadSapCustomer();
    const foundries = await this.loadFoundries(null, null, getAllFoundries);
    const technologies = await this.loadTechnologies(
      null,
      null,
      getAllTechnologies
    );
    const processes = await this.loadProcess(null, null, getAllProccesses);
    const groups = await this.loadGroups(null, null, getAllGroups);
    this.setState({
      sapCustomerList,
      foundries,
      technologies,
      processes,
      groups,
      loading: false,
    });
  }

  packageTypeHandler = (event) => {
    const packageType = event.target.value;
    this.setState({ packageType });
  };

  sapCustomerFilter_onChange = async (event) => {
    this.setState({ loading: true });
    const idoc = event.target.value;
    let usernameList = [];
    if (idoc != "") {
      usernameList = await getUsernameBySapcustomer(idoc);
    }
    const foundries = await this.loadFoundries(
      idoc,
      null,
      getDownloadedFoundries
    );
    const technologies = await this.loadTechnologies(
      idoc,
      null,
      getDownloadedTechnologies
    );
    const processes = await this.loadProcess(
      idoc,
      null,
      getDownloadedProccesses
    );
    const groups = await this.loadGroups(idoc, null, getDownloadedGroups);
    this.setState({
      selectedSapCustmer: idoc,
      usernameList,
      selectedUserId: "",
      foundries,
      selectedFoundry: "",
      technologies,
      selectedTechnology: "",
      processes,
      selectedProcess: "",
      groups,
      selectedGroup: "",
    });
    this.setState({ loading: false });
  };

  loadSapCustomer = async () => {
    const sapCustomers = sessionStorage.getItem(Constants.SESSION_SAP_CUSTOMER);
    if (sapCustomers) {
      return sapCustomers ? JSON.parse(sapCustomers) : null;
    } else {
      const sapCustomers = await getSapCustomer();
      sessionStorage.setItem(
        Constants.SESSION_SAP_CUSTOMER,
        JSON.stringify(sapCustomers)
      );
      return sapCustomers;
    }
  };

  loadFoundries = async (idoc, userId, getFoundries) => {
    return await this.loadFilter(
      idoc,
      userId,
      Constants.SESSION_FOUNDRY,
      getFoundries
    );
  };

  loadTechnologies = async (idoc, userId, getTechnologies) => {
    return await this.loadFilter(
      idoc,
      userId,
      Constants.SESSION_TECHNOLOGY,
      getTechnologies
    );
  };

  loadProcess = async (idoc, userId, getProcesses) => {
    return await this.loadFilter(
      idoc,
      userId,
      Constants.SESSION_PROCCESS,
      getProcesses
    );
  };

  loadGroups = async (idoc, userId, getGroups) => {
    return await this.loadFilter(
      idoc,
      userId,
      Constants.SESSION_GROUP,
      getGroups
    );
  };

  loadFilter = async (idoc, userId, key, beFunc) => {
    let sessionData = sessionStorage.getItem(key + idoc);
    if (sessionData) {
      sessionData = sessionData ? JSON.parse(sessionData) : null;
    } else {
      sessionData = await beFunc(idoc, userId);
    }
    if (!idoc) {
      sessionStorage.setItem(key, JSON.stringify(sessionData));
    }
    return sessionData;
  };

  sapUsernameFilter_onChange = async (event) => {
    this.setState({ loading: true });
    const selectedUserId = event.target.value;
    this.setState({
      loading: false,
      selectedUserId,
    });
  };

  foundryFilterHandler = (item) => {
    const selectedFoundry = item.target.value;
    this.setState({ selectedFoundry });
  };

  technologyFilterHandler = (item) => {
    const selectedTechnology = item.target.value;
    this.setState({ selectedTechnology });
  };

  processFilterHandler = (item) => {
    const selectedProcess = item.target.value;
    this.setState({ selectedProcess });
  };

  familyFilterHandler = (item) => {
    const selectedGroup = item.target.value;
    this.setState({ selectedGroup });
  };

  startDateHandler = (event) => {
    const startedDate = event.target.value;
    this.setState({ startedDate });
  };

  endDateHandler = (event) => {
    const endedDate = event.target.value;
    this.setState({ endedDate });
  };

  productCodeHandler = (event) => {
    const productcode = event.target.value;
    this.setState({ productcode });
  };

  handleHistoryLevel = (event) => {
    var historyLevel;
    if (event.target.checked) {
      historyLevel = Constants.PRODUCT_LEVEL;
    } else {
      historyLevel = Constants.PART_LEVEL;
    }
    this.setState({ historyLevel });
  };
}
