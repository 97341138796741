import React from "react";

class FooterBar extends React.Component {
  render() {
    const styles = {
      marginBottom: "5px !important"
    };

    return (
      <div className="c-footer-wrapper">
        <div className="o-cors-container" style={styles}>
          <footer
            className="c-footer"
            id="footer"
            role="contentinfo"
            style={{ padding: "1px" }}
          >
            <hr />
            <div className="c-footer__row row c-footer__social">
              <div className="c-footer__section column small-12 large-10">
                <ul className="o-list o-list--inline">
                  <li className="o-list__item c-footer__social-link">
                    <a
                      href="http://www.facebook.com/Arm"
                      target="_blank"
                      rel="noopener noreferrer"
                      title=""
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li className="o-list__item c-footer__social-link">
                    <a
                      href="http://www.linkedin.com/company/Arm"
                      target="_blank"
                      rel="noopener noreferrer"
                      title=""
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li className="o-list__item c-footer__social-link">
                    <a
                      href="http://www.instagram.com/Arm"
                      target="_blank"
                      rel="noopener noreferrer"
                      title=""
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li className="o-list__item c-footer__social-link">
                    <a
                      href="http://www.twitter.com/Arm"
                      target="_blank"
                      rel="noopener noreferrer"
                      title=""
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li className="o-list__item c-footer__social-link">
                    <a
                      href="https://www.youtube.com/user/Armflix"
                      target="_blank"
                      rel="noopener noreferrer"
                      title=""
                    >
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="c-footer__row row c-footer__legal">
              <div className="c-footer__section column">
                <ul className="o-list o-list--inline c-footer--legal-links">
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://www.arm.com/company/policies/cookies"
                      title="Footer link to Cookie Policy"
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://www.arm.com/company/policies/terms-and-conditions"
                      title="Footer link to Terms of Use"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://www.arm.com/company/policies/privacy"
                      title="Footer link to Privacy Policy"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://www.arm.com/company/policies/accessibility"
                      title="Footer link to Accessibility"
                    >
                      Accessibility
                    </a>
                  </li>
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://login.arm.com/subscriptions.php"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Footer link to Subscription Center"
                    >
                      Subscription Center
                    </a>
                  </li>
                  <li className="o-list__item c-footer__legal-link">
                    <a
                      href="https://www.arm.com/company/policies/trademarks"
                      title="Trademarks Footer Link"
                    >
                      Trademarks
                    </a>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div
              className="c-footer__row row c-footer__copyright "
              style={styles}
            >
              <div className="c-footer__section column">
                Copyright © 1995-{(new Date().getFullYear())} Arm Limited (or its affiliates). All
                rights reserved.
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default FooterBar;
