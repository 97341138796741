import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import format from "string-format";

import isEnableDisplay from "./common/ulti";
import Table from "./common/table";
import { getPackageType } from "../services/ProductServices";

class InCartTable extends Component {
  META_COLUMNS = {
    key: "product",
    key_row: "product_code",
    columns: [
      {
        path: "product_name",
        label: "Product Description",
        columnStyleWidth: "50%"
      },
      {
        path: "packageType",
        label: "Entitlement",
        columnStyleWidth: "15%",
        content: product => this.renderPackageTypeCol(product)
      },
      {
        path: "product_code",
        label: "Product Code",
        columnStyleWidth: "15%"
      },
      {
        path: "technology_name",
        label: "Technology",
        columnStyleWidth: "20%"
      },
      {
        path: "product_group_name",
        label: "Product Groups",
        columnStyleWidth: "20%"
      },
      {
        label: "Latest Version",
        key: "revision",
        columnStyleWidth: "20%",
        content: product => {
          return format("{0}-{1}", product.revision, product.version);
        }
      },
      {
        key: "size",
        label: "Size(MB)",
        columnStyleWidth: "10%",
        content: product => {
          var sizeMB = product.size / (1000 * 1000);
          sizeMB = sizeMB.toLocaleString(undefined, {
            maximumFractionDigits: 2
          });
          return format("{0}", sizeMB);
        }
      },
      {
        id: "delete",
        key: "delete",
        columnStyleWidth: "20%",
        headComponent: () => this.renderDeleteAll(),
        content: product => this.renderDelete(product)
      }
    ]
  };

  renderDeleteAll() {
    return (
      this.props.products.length && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => this.props.removeAllHandler()}
        >
          Delete All
        </Button>
      )
    );
  }

  renderDelete(product) {
    return (
      <Button
        style={{ marginLeft: "20px" }}
        variant="outlined"
        color="secondary"
        onClick={() => this.props.removeItemHandler(product)}
      >
        Delete
      </Button>
    );
  }

  render() {
    const { products, enableDisplay } = this.props;
    return (
      <React.Fragment>
        <div
          className="display-enable"
          style={{ display: isEnableDisplay(enableDisplay) }}
        />
        <Table
          table_id="cartTable"
          metadata={this.META_COLUMNS}
          data={products}
          isSelected={this.isSelected}
        />
      </React.Fragment>
    );
  }

  renderPackageTypeCol = product => {
    return product.packageType != null ? (
      product.packageType
    ) : (
      <Link to={`#`} onClick={e => this.getPackageType(e, product)}>
        Show
      </Link>
    );
  };

  getPackageType = async (e, product) => {
    e.stopPropagation();
    product.packageType = await getPackageType(product);
    this.setState({ product });
  };
}

export default InCartTable;
