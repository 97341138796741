import React, { Component } from "react";
import { any } from "prop-types";
import { createBrowserHistory } from "history";

import {
  getProducts,
  hasErrataNoticesByProduct,
} from "../services/ProductServices";
import ProductFilters from "./productFilters";
import ProductTable from "./productTable";
import { getReadMeContent } from "../services/ProductServices";
import ShowReadMePage from "../pages/readMePage";
import CustomSnackbar from "./common/CustomSnackbar";
import {
  CONNECTION_ERROR_MSG,
  SNACKBAR_VARIANT,
  PRODUCT_SESSION_KEY,
} from "./common/Constants";
import ResponseError from "../error/ResponseError";

class ProductPage extends Component {
  state = {
    loading: false,
    enableDisplay: true,
    isEntitled: true,
    totalProduct: 0,
    products: [],
    filteredProducts: [],
    hasNotices: [],
    searchText: "",
    foundries: [],
    filteringFoundries: [],
    selectedFoundries: [],
    processes: [],
    filteringProcesses: [],
    selectedProcesses: [],
    groups: [],
    filteringGroups: [],
    selectedGroups: [],
    technologies: [],
    filteringTechnologies: [],
    selectedTechnologies: [],
    families: [],
    filteringFamilies: [],
    selectedFamilies: [],
    openProductReport: false,
    product: any,
    content: "",
    dialogLoading: true,
    snackbarOpen: false,
    snackbarMsg: CONNECTION_ERROR_MSG,
    snackbarVariant: SNACKBAR_VARIANT.ERROR,
    rowsPerPage: 20,
  };
  render() {
    const {
      enableDisplay,
      loading,
      filteredProducts,
      hasNotices,
      filteringFoundries,
      selectedFoundries,
      filteringTechnologies,
      selectedTechnologies,
      filteringProcesses,
      selectedProcesses,
      filteringGroups,
      selectedGroups,
      filteringFamilies,
      selectedFamilies,
      isEntitled,
      openProductReport,
      product,
      content,
      dialogLoading,
      snackbarOpen,
      snackbarMsg,
      snackbarVariant,
    } = this.state;

    const { isSubpage, productGroup } = this.props;

    const { flgGrouping, inCartProducts } = this.props;
    return (
      <div>
        <div className="row" style={{ maxWidth: "110rem" }}>
          <div className="col-md-3">
            <ProductFilters
              loading={loading}
              enableDisplay={enableDisplay}
              foundries={filteringFoundries}
              selectedFoundries={selectedFoundries}
              technologies={filteringTechnologies}
              selectedTechnologies={selectedTechnologies}
              processes={filteringProcesses}
              selectedProcesses={selectedProcesses}
              groups={filteringGroups}
              selectedGroups={selectedGroups}
              families={filteringFamilies}
              selectedFamilies={selectedFamilies}
              filterHandler={this.productFilterHandler}
              isEntitled={isEntitled}
              entitlementHandler={this.entitlementHandler}
            />
          </div>
          <div className="col-md-9">
            <div style={{ paddingBottom: "10px" }}>
              <input
                type="search"
                className="form-control mysearch-box"
                aria-describedby="inputGroup-sizing-sm"
                onKeyDown={(event) => this.productSearchHandler(event)}
                placeholder="Search products"
              />
            </div>
            <ProductTable
              title="Physical IP Products"
              isSubpage={isSubpage}
              loading={this.state.loading}
              flgGrouping={flgGrouping}
              enableDisplay={enableDisplay}
              products={filteredProducts}
              hasNotices={hasNotices}
              handleChangePage={this.handleChangePage}
              total={filteredProducts.length}
              inCartProducts={inCartProducts}
              onSelectClickHandler={this.onSelectClickHandlerHook}
              onNameClickHandler={this.nameClickHandler}
              entitlementHandler={this.entitlementHandler}
              openProductReport={this.openProductReport}
              productGroup={productGroup}
            />
          </div>
        </div>
        <ShowReadMePage
          open={openProductReport}
          product={product}
          content={content}
          dialogLoading={dialogLoading}
          handleClose={this.closeProductReport}
        />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMsg}
          handleClose={this.handleCloseSnackbar}
          variant={snackbarVariant}
        />
      </div>
    );
  }

  closeProductReport = () => {
    this.setState({ openProductReport: false });
  };

  updateQueryString = (
    selectedFoundries,
    selectedTechnologies,
    selectedProcesses,
    selectedGroups,
    selectedFamilies
  ) => {
    const history = createBrowserHistory();

    var params = {
      foundry: selectedFoundries.join(),
      technology: selectedTechnologies.join(),
      process: selectedProcesses.join(),
      groups: selectedGroups.join(),
      family: selectedFamilies.join(),
    };

    var esc = encodeURIComponent;
    var query = Object.keys(params)
      .filter((c) => params[c] !== "")
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    const computedQuery = query === "" ? "/products" : "/products/s?" + query;
    history.push(computedQuery);
  };

  refreshProductsFromURL(queryParams) {
    const {
      foundries,
      technologies,
      processes,
      groups,
      families,
    } = queryParams;

    this.setState({
      loading: true,
      selectedFoundries: foundries,
      selectedTechnologies: technologies,
      selectedProcesses: processes,
      selectedGroups: groups,
      selectedFamilies: families,
    });

    this.productFilterHandler(
      foundries,
      technologies,
      processes,
      groups,
      families
    );
  }

  async componentDidMount() {
    const { isEntitled } = this.props;

    const {
      selectedFoundries = [],
      selectedTechnologies = [],
      selectedProcesses = [],
      selectedGroups = [],
      selectedFamilies = [],
    } = this.props;

    await this.getProductFromBE(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies,
      isEntitled
    );

    if (typeof this.props.queryParams != "undefined") {
      this.refreshProductsFromURL(this.props.queryParams);
    }
  }

  entitlementHandler = async (isEntitled) => {
    const { isSubpage } = this.props;
    const {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies,
    } = this.state;
    /**
     * if it is detail page, which is displayed when user click on product group,
     * we need to call with selected information;
     * if it is the main page which is display all product either entitled or not,
     * we need to get all products without filtering information since we perform local filtering
     */
    await this.getProductFromBE(
      isSubpage ? selectedFoundries : [],
      isSubpage ? selectedTechnologies : [],
      isSubpage ? selectedProcesses : [],
      isSubpage ? selectedGroups : [],
      isSubpage ? selectedFamilies : [],
      isEntitled
    );

    /**
     * escalate the value of switch up to top layer, App.js, to let it be transfered between pages
     */
    const { licensedProductHandler } = this.props;
    licensedProductHandler(isEntitled);
  };

  productFilterHandler = async (
    selectedFoundries,
    selectedTechnologies,
    selectedProcesses,
    selectedGroups,
    selectedFamilies
  ) => {
    this.setState({ loading: true, enableDisplay: false });
    const { searchText, rowsPerPage } = this.state;
    this.updateQueryString(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
    const filterProductCatergories = [
      { category: selectedFoundries, key: "foundry_id" },
      { category: selectedTechnologies, key: "technology_id" },
      { category: selectedProcesses, key: "process_id" },
      { category: selectedGroups, key: "product_group_id" },
      // { category: selectedFamilies, key: "family_id" },
      { category: [searchText], filterHandler: this.searchProductHandler },
    ];

    const filteredProducts = this.filterProductsByCategories(
      filterProductCatergories
    );

    const foundryCatergories = [
      // { category: selectedFoundries, key: "foundry_id" },
      { category: selectedTechnologies, key: "technology_id" },
      { category: selectedProcesses, key: "process_id" },
      { category: selectedGroups, key: "product_group_id" },
      // { category: selectedFamilies, key: "family_id" },
      { category: [searchText], filterHandler: this.searchProductHandler },
    ];

    const filteringFoundries = this.getFoundyFilterValue(foundryCatergories);

    const technologyFilterCategories = [
      { category: selectedFoundries, key: "foundry_id" },
      // { category: selectedTechnologies, key: "technology_id" },
      { category: selectedProcesses, key: "process_id" },
      { category: selectedGroups, key: "product_group_id" },
      // { category: selectedFamilies, key: "family_id" },
      { category: [searchText], filterHandler: this.searchProductHandler },
    ];
    const filteringTechnologies = this.getTechnologyFilterValue(
      technologyFilterCategories
    );

    const processFilterCategories = [
      { category: selectedFoundries, key: "foundry_id" },
      { category: selectedTechnologies, key: "technology_id" },
      // { category: selectedProcesses, key: "process_id" },
      { category: selectedGroups, key: "product_group_id" },
      // { category: selectedFamilies, key: "family_id" },
      { category: [searchText], filterHandler: this.searchProductHandler },
    ];

    const filteringProcesses = this.getProcessFilterValue(
      processFilterCategories
    );

    const groupFilterCategories = [
      { category: selectedFoundries, key: "foundry_id" },
      { category: selectedTechnologies, key: "technology_id" },
      { category: selectedProcesses, key: "process_id" },
      // { category: selectedGroups, key: "product_group_id" },
      // { category: selectedFamilies, key: "family_id" },
      { category: [searchText], filterHandler: this.searchProductHandler },
    ];
    const filteringGroups = this.getGroupFilterValue(groupFilterCategories);

    // const familyFilterCategories = [
    //   { category: selectedFoundries, key: "foundry_id" },
    //   { category: selectedTechnologies, key: "technology_id" },
    //   { category: selectedProcesses, key: "process_id" },
    //   { category: selectedGroups, key: "product_group_id" },
    //   // { category: selectedFamilies, key: "family_id" },
    //   { category: [searchText], filterHandler: this.searchProductHandler }
    // ];
    // const filteringFamilies = this.getFamilyFilterValue(familyFilterCategories);

    await this.handleChangePage(0, rowsPerPage, filteredProducts);

    this.setState({
      loading: false,
      enableDisplay: true,
      filteredProducts,
      filteringFoundries,
      filteringTechnologies,
      filteringGroups,
      // filteringFamilies,
      filteringProcesses,
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies,
    });
  };

  getFoundyFilterValue(categories) {
    const foundry_info = {
      code: "foundry_id",
      name: "foundry_name",
    };
    return this.getFilterValue(categories, foundry_info);
  }

  getTechnologyFilterValue(categories) {
    const foundry_info = {
      code: "technology_id",
      name: "technology_name",
    };
    return this.getFilterValue(categories, foundry_info);
  }

  getProcessFilterValue(categories) {
    const foundry_info = {
      code: "process_id",
      name: "process_name",
    };
    return this.getFilterValue(categories, foundry_info);
  }

  getGroupFilterValue(categories) {
    const foundry_info = {
      code: "product_group_id",
      name: "product_group_name",
    };
    return this.getFilterValue(categories, foundry_info);
  }

  // getFamilyFilterValue(categories) {
  //   const foundry_info = {
  //     code: "family_id",
  //     name: "family_name"
  //   };
  //   return this.getFilterValue(categories, foundry_info);
  // }

  getFilterValue(categories, category_info) {
    const { code, name } = category_info;
    const filteredProducts = this.filterProductsByCategories(categories);
    let filteredCategory = new Map();
    for (var i = 0; i < filteredProducts.length; i++) {
      const product = filteredProducts[i];
      const cat_code = product[code];
      const cat_name = product[name];
      let category = filteredCategory.get(cat_code);
      if (category) {
        category.count += 1;
      } else {
        category = {};
        category["id"] = cat_code;
        category["name"] = cat_name;
        category["count"] = 1;
        filteredCategory.set(cat_code, category);
      }
    }
    return [...filteredCategory.values()];
  }

  filterProductsByCategories(categories) {
    const { products } = this.state;
    if (!categories) return products;
    let filteredProducts = products;
    for (var i = 0; i < categories.length; i++) {
      const { category, key, filterHandler } = categories[i];

      filteredProducts = this.filterProducts(
        filteredProducts,
        category,
        key,
        filterHandler
      );
    }
    return filteredProducts;
  }

  filterProducts = (products, category, key, filterHandler) => {
    if (!category || category.length === 0) return products;
    const filteredProducts = products.filter((product) => {
      return category.filter((fitlerValue) => {
        if (filterHandler) {
          return filterHandler(product, fitlerValue);
        } else {
          return fitlerValue === product[key];
        }
      }).length;
    });
    return filteredProducts;
  };

  handleChangePage = async (page, rowsPerPage, products) => {
    this.setState({ loading: true, rowsPerPage });
    const selected_products = products
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((product) => product.product_code);
    const hasNotices = await hasErrataNoticesByProduct(
      selected_products.join(",")
    );
    this.setState({ hasNotices, loading: false });
  };

  getProductFromBE = async (
    selectedFoundries,
    selectedTechnologies,
    selectedProcesses,
    selectedGroups,
    selectedFamilies,
    isEntitled
  ) => {
    const { rowsPerPage } = this.state;
    const flgGrouping = this.props.flgGrouping;
    this.setState({ loading: true, enableDisplay: false });

    let result = null;
    if (!this.props.isSubpage) {
      const products = sessionStorage.getItem(PRODUCT_SESSION_KEY);
      result = products ? JSON.parse(products) : null;
    }

    try {
      if (result === null) {
        result = await getProducts(
          selectedFoundries,
          selectedTechnologies,
          selectedProcesses,
          selectedGroups,
          selectedFamilies,
          flgGrouping,
          isEntitled
        );
        if (!this.props.isSubpage) {
          sessionStorage.setItem(PRODUCT_SESSION_KEY, JSON.stringify(result));
        }
      }

      const {
        totalProduct,
        products,
        foundries,
        processes,
        technologies,
        groups,
        families,
      } = result;
      const filteredProducts = [...products];

      await this.handleChangePage(0, rowsPerPage, filteredProducts);

      this.setState({
        isEntitled,
        loading: false,
        enableDisplay: true,
        totalProduct,
        products,
        filteredProducts,
        foundries,
        filteringFoundries: foundries,
        selectedFoundries,
        technologies,
        filteringTechnologies: technologies,
        selectedTechnologies,
        processes,
        filteringProcesses: processes,
        selectedProcesses,
        groups,
        filteringGroups: groups,
        selectedGroups,
        families,
        filteringFamilies: families,
        selectedFamilies,
      });
    } catch (error) {
      if (error instanceof ResponseError) {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: error.messages,
          snackbarOpen: true,
        });
      } else {
        this.setState({
          snackbarVariant: SNACKBAR_VARIANT.ERROR,
          snackbarMsg: CONNECTION_ERROR_MSG,
          snackbarOpen: true,
        });
      }
      this.setState({
        loading: false,
        enableDisplay: true,
      });
    }
  };

  productSearchHandler = async (event) => {
    if (event.key !== "Enter") {
      return;
    }
    const value = event.target.value.toLowerCase();
    await this.setState({ searchText: value });
    const {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies,
    } = this.state;
    this.productFilterHandler(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
  };

  searchProductHandler = (product, filterValue) => {
    const searchText = this.buildSearchText(product);
    return searchText.indexOf(filterValue) + 1;
  };

  buildSearchText = (product) => {
    const searchText = `${product.product_name} ${product.technology_name} ${product.product_group_name} ${product.product_code}`;
    return searchText.toLowerCase();
  };

  onSelectClickHandlerHook = async (product) => {
    const { isEntitled } = this.state;
    this.setState({ loading: true, enableDisplay: false });
    await this.props.onSelectClickHandler(product, isEntitled);
    this.setState({ loading: false, enableDisplay: true });
  };

  openProductReport = async (product) => {
    // Clear content so that it doenst display previous readme when loading a new one
    await this.setState({
      openProductReport: true,
      content: "",
      dialogLoading: true,
    });
    var product_code = product["product_code"];
    let readme_content;
    //Run axios.get for readme content
    readme_content = await getReadMeContent(product_code);
    await this.setState({
      content: readme_content["data"],
      dialogLoading: false,
    });
  };

  handleCloseSnackbar = () => {
    const snackbarOpen = false;
    this.setState({ snackbarOpen });
  };
}

export default ProductPage;
