import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { DOWNLOAD_ITEMS } from "./Constants";
import { sizeToText } from "../util/commonUtil";
import PluginDownloadSetting from "./PluginDownloadSetting";

class IncartBox extends Component {
  state = {
    pluginSettingOpen: false,
    downloadMethod: "HTTPS",
  };

  render() {
    const downloadMode = DOWNLOAD_ITEMS.PRODUCT_LEVEL;
    const { totalProduct, totalSize } = this.props;
    const { pluginSettingOpen, downloadMethod } = this.state;

    return (
      <React.Fragment>
        <div className="card col-md-12 left_box">
          <div className="row" style={{ padding: "0px 20px" }}>
            <div className="col-md-7" style={{ padding: "0px", width: "100%" }}>
              <Typography
                variant="button"
                gutterBottom
                style={{
                  paddingTop: "25px",
                }}
              >
                Total products: {totalProduct}
              </Typography>
              <Typography
                variant="button"
                gutterBottom
                display="block"
                style={{ paddingBottom: "25px" }}
              >
                Total sizes: {sizeToText(totalSize)}
              </Typography>
            </div>
          </div>
          <div className="row" style={{ padding: "0px" }}>
            <div className="col-md-12" style={{ padding: "0px" }}>
              <Divider />
            </div>
          </div>
          <div className="row" style={{ padding: "20px" }}>
            <button
              type="button"
              style={{
                border: "0px",
                width: "100%",
                backgroundColor: "#ffc700",
                margin: "0px",
                height: "80%",
              }}
              onClick={this.saveCartHandlerHook}
            >
              Save Cart
            </button>
          </div>
          <div className="row" style={{ padding: "0px 20px 20px 20px" }}>
            <div style={{ width: "50%", padding: "0px 0px 0px 0px" }}>
              <button
                type="button"
                style={{
                  padding: ".4em .6em",
                  margin: "0",
                  height: "40px",
                  backgroundColor: "#ffc700",
                  width: "100%",
                }}
                disabled={totalProduct === 0}
                onClick={() => this.downloadHandlerHook(downloadMode)}
              >
                Download By:
              </button>
            </div>
            <div style={{ width: "50%", padding: "0px 0px 0px 5px" }}>
              <Select
                id="download-method"
                disabled={totalProduct === 0}
                value={downloadMethod}
                onChange={this.downloadMethodHandler}
                style={{
                  padding: ".4em .6em",
                  margin: "0",
                  height: "43px",
                  backgroundColor: "#ffc700",
                  width: "100%",
                }}
              >
                <MenuItem value={"HTTPS"}>HTTPS</MenuItem>
                <MenuItem value={"PLLUGIN"}>Plugin</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <PluginDownloadSetting
          open={pluginSettingOpen}
          cancelHandler={this.cancelHandler}
          okHandler={this.okHandler}
        />
      </React.Fragment>
    );
  }

  saveCartHandlerHook = () => {
    const { saveCartHandler } = this.props;
    saveCartHandler();
  };

  downloadAllHandlerHook = (downloadMode) => {
    const { history, downloadAllHandler } = this.props;
    history.push("/download");
    downloadAllHandler(downloadMode);
  };

  pluginDownloadHandlerHook = (downloadMode) => {
    const { pluginDownloadHandler } = this.props;
    pluginDownloadHandler(downloadMode);
  };

  okHandler = (saveTo, extractTo, sslCheck) => {
    const { pluginDownloadHandler } = this.props;
    pluginDownloadHandler(saveTo, extractTo, sslCheck);
  };

  cancelHandler = () => {
    this.setState({ pluginSettingOpen: false });
  };

  downloadMethodHandler = (event) => {
    this.setState({ downloadMethod: event.target.value });
  };

  downloadHandlerHook = (downloadMode) => {
    const { downloadMethod } = this.state;
    if (downloadMethod === "HTTPS") {
      this.downloadAllHandlerHook(downloadMode);
    } else {
      this.setState({ pluginSettingOpen: true });
    }
  };
}

export default withRouter(IncartBox);
