import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function PrintSummary({ title, total, error, success, cancelled }) {
  return (
    <React.Fragment>
      <Typography variant="h6" style={{ padding: "20px 0px 0px 0px" }}>
        {title}
      </Typography>
      <Typography style={{ padding: "2px 15px" }}>
        Total download: {total}
      </Typography>
      <Typography style={{ padding: "2px 15px" }}>
        Sucess download: {success}
      </Typography>
      <Typography style={{ padding: "2px 15px" }}>
        Error download: {error}
      </Typography>
      <Typography style={{ padding: "2px 15px" }}>
        Cancel download: {cancelled}
      </Typography>
    </React.Fragment>
  );
}

function PrintDetail({ title, parts }) {
  return (
    <React.Fragment>
      <Typography variant="h6" style={{ padding: "20px 0px 0px 0px" }}>
        {title}: {parts !== undefined && parts.length}
      </Typography>
      {parts.map(part => (
        <React.Fragment key={part.part_name}>
          <Typography style={{ padding: "5px 50px 0px 15px" }}>
            <b>
              {part.part_name} {part.revision}-{part.version}
            </b>
          </Typography>
          <Typography style={{ padding: "0px 50px 10px 15px" }}>
            {part.description}
          </Typography>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

function DownloadReportByProduct(props) {
  const scroll = "paper";
  const {
    open,
    product,
    successDownloadingParts,
    errorDownloadingParts,
    canceledDownloadingParts,
    handleClose
  } = props;

  const total = product.parts !== undefined ? product.parts.length : 0;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle
        id="scroll-dialog-title"
        style={{ padding: "20px 70px 10px 20px" }}
      >
        {product !== undefined && product.product_name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <PrintSummary
            title="Summary"
            total={total}
            error={errorDownloadingParts.length}
            success={successDownloadingParts.length}
            cancelled={canceledDownloadingParts.length}
          />
          <PrintDetail title="Error Parts" parts={errorDownloadingParts} />
          <PrintDetail title="Success Parts" parts={successDownloadingParts} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadReportByProduct;
