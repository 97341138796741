import DesignStartError from "./DesignStartError";

import { CHECKSUM_ERROR_MSG } from "../components/common/Constants";

class ValidationError extends DesignStartError {
  constructor() {
    super(CHECKSUM_ERROR_MSG);
  }
}

export default ValidationError;
