import React from "react";
import { DOWNLOAD_STATUS } from "./Constants";

const ProgressBar = ({ percentage, status = DOWNLOAD_STATUS.SUCCESS }) => {
  if (percentage === undefined) {
    percentage = 0;
  } else if (percentage < 1) {
    percentage = 1;
  }
  if (percentage) percentage = Math.floor(percentage);
  const progressStyle = { width: `${percentage}%` };
  let progressClass = `progress-bar w-${percentage}`;
  if (percentage < 100) {
    progressClass = `${progressClass} progress-bar-striped progress-bar-animated`;
  }
  if (status === DOWNLOAD_STATUS.ERROR) {
    progressClass = `${progressClass} bg-danger`;
  }
  return (
    <div className="progress">
      <div
        className={progressClass}
        role="progressbar"
        style={progressStyle}
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {percentage ? `${percentage}%` : ""}
      </div>
    </div>
  );
};

export default ProgressBar;
