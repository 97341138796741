import React, { Component } from "react";
import isEnableDisplay from "./common/ulti";
import CommonFilter from "./common/CommonFilter";
import EntitlementSwitch from "./entitlementSwitch";
import * as Constants from "../components/common/Constants";

class ProductFilters extends Component {
  state = {};
  render() {
    const {
      loading,
      enableDisplay,
      foundries,
      selectedFoundries,
      technologies,
      selectedTechnologies,
      processes,
      selectedProcesses,
      groups,
      selectedGroups,
      // TODO: Commenting out family filtering to address BZ:107408
      // families,
      // selectedFamilies,
      isEntitled,
      entitlementHandler
    } = this.props;
    return (
      <React.Fragment>
        <div
          className="display-enable"
          style={{ display: isEnableDisplay(enableDisplay) }}
        />
        <EntitlementSwitch
          isEntitled={isEntitled}
          onChangeHandler={entitlementHandler}
        />
        <CommonFilter
          title={Constants.FILTER_FOUNDRY_TITLE}
          data={foundries}
          selectedItems={selectedFoundries}
          onItemClickHandler={this.foundryFilterHandler}
          loading={loading}
        />
        <CommonFilter
          title={Constants.FILTER_TECHNOLOGY_TITLE}
          data={technologies}
          selectedItems={selectedTechnologies}
          onItemClickHandler={this.technologyFilterHandler}
          loading={loading}
        />
        <CommonFilter
          title={Constants.FILTER_PROCESS_TITLE}
          data={processes}
          selectedItems={selectedProcesses}
          onItemClickHandler={this.processFilterHandler}
          loading={loading}
        />
        <CommonFilter
          title={Constants.FILTER_GROUPS_TITLE}
          data={groups}
          selectedItems={selectedGroups}
          onItemClickHandler={this.groupFilterHandler}
          loading={loading}
        />
        {/* <CommonFilter
          title={Constants.FILTER_FAMILY_TITLE}
          data={families}
          selectedItems={selectedFamilies}
          onItemClickHandler={this.familyFilterHandler}
          loading={loading}
        /> */}
      </React.Fragment>
    );
  }

  foundryFilterHandler = foudry => {
    const foundries = [...this.props.selectedFoundries];
    let {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    } = this.props;

    selectedFoundries = foundries.filter(c => c !== foudry.id);

    if (selectedFoundries.length === foundries.length) {
      selectedFoundries = [...selectedFoundries, foudry.id];
    }

    this.props.filterHandler(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
  };

  technologyFilterHandler = tech => {
    const technologies = [...this.props.selectedTechnologies];
    let {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    } = this.props;

    selectedTechnologies = technologies.filter(c => c !== tech.id);
    if (selectedTechnologies.length === technologies.length) {
      selectedTechnologies = [...selectedTechnologies, tech.id];
    }

    this.props.filterHandler(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
  };

  processFilterHandler = process => {
    const processes = [...this.props.selectedProcesses];
    let {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    } = this.props;

    selectedProcesses = processes.filter(c => c !== process.id);
    if (selectedProcesses.length === processes.length) {
      selectedProcesses = [...selectedProcesses, process.id];
    }

    this.props.filterHandler(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
  };

  groupFilterHandler = group => {
    const groups = [...this.props.selectedGroups];
    let {
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    } = this.props;

    selectedGroups = groups.filter(c => {
      return c !== group.id;
    });
    if (selectedGroups.length === groups.length) {
      selectedGroups = [...groups, group.id];
    }
    this.props.filterHandler(
      selectedFoundries,
      selectedTechnologies,
      selectedProcesses,
      selectedGroups,
      selectedFamilies
    );
  };

  // familyFilterHandler = family => {
  //   const families = [...this.props.selectedFamilies];
  //   let {
  //     selectedFoundries,
  //     selectedTechnologies,
  //     selectedProcesses,
  //     selectedGroups,
  //     selectedFamilies
  //   } = this.props;

  //   selectedFamilies = families.filter(c => {
  //     return c !== family.id;
  //   });
  //   if (selectedFamilies.length === families.length) {
  //     selectedFamilies = [...families, family.id];
  //   }
  //   this.props.filterHandler(
  //     selectedFoundries,
  //     selectedTechnologies,
  //     selectedProcesses,
  //     selectedGroups,
  //     selectedFamilies
  //   );
  // };
}

export default ProductFilters;
